import React, { useEffect, useState, useRef } from "react";
import { BsPerson } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import style from "../Style/CSS.module.css"; // Import custom CSS module
import {
  FaBirthdayCake,
  FaLocationArrow,
  FaPhoneAlt,
  FaUser,
} from "react-icons/fa"; // Import icons
import {
  MdEmail,
  MdOutlineAttachEmail,
  MdOutlineModeEdit,
  MdOutlinePhone,
  MdOutlineTitle,
} from "react-icons/md"; // Import icons
import axios from "axios";
import { RiLockPasswordFill } from "react-icons/ri"; // Import icons
import "../Style/CSS.css"; // Import custom CSS file
import defaultProfilePic from "../../src/pages/components/assets/icons/person.png";

import loginStyle from "../pages/ContactForm/contact.module.css";
import { CiEdit } from "react-icons/ci";
import { ApiEndPoint, decodeToken } from "../pages/components/utils/utlis";

const User_profile = ({ user }) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [adminData, setAdminData] = useState({
    name: user.name,
    email: user.email,
    password: user.password,
    profilePicture: user.profilePicture,
    phone: user.phone,
  });
  const jwtToken = sessionStorage.getItem("jwtToken");
  const decod = decodeToken(jwtToken);
  const { decodedToken } = decod;
  const userId = user.id;

  const [profilePicBase64, setProfilePicBase64] = useState(user.profilePicture);
  const [editableFields, setEditableFields] = useState(false);
  const nameInputRef = useRef(null); // Create a ref for the name input field

  useEffect(() => {
    if (isDeleted) {
      setAdminData(null);
    } else {
      setAdminData({
        name: user.name,
        email: user.email,
        password: user.password,
        phone: user.phone,
        profilePicture: user.profilePicture,
      });
      setProfilePicBase64(user.profilePicture);
    }
  }, [user, isDeleted]);

  const toggleEdit = () => {
    setEditableFields((prev) => !prev);
    if (!editableFields) {
      setTimeout(() => {
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }
      }, 0);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        setProfilePicBase64(base64String);
        setAdminData((prevData) => ({ ...prevData, profilePicture: base64String }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e, field) => {
    if (e && e.target) {
      setAdminData((prevData) => ({ ...prevData, [field]: e.target.value }));
    }
  };

  const handlePhoneChange = (value) => {
    setAdminData((prevData) => ({ ...prevData, phone: value }));
  };

  const handleSave = async () => {
    await axios
      .put(`${ApiEndPoint}/admin/${userId}`, adminData)
      .then((response) => {
        if (response.status === 200) {
          setAdminData(response.data.adminData); // Update with the response data
          setEditableFields(false);
        }
      })
      .catch((error) => {
        console.error(error); // Log any errors
      });
  };

  const handleDelete = async () => {
    await axios
      .put(`${ApiEndPoint}/adminDelete/${userId}`)
      .then((response) => {
        if (response.status === 200) {
          setAdminData(null);
        }
        setEditableFields(false);
      })
      .catch((error) => {
        console.error(error); // Log any errors
      });
  };

  return (
    <div style={{ height: "91%", width: "100%" }}>
      {adminData ? (
        <div>
          <div
            id="chk"
            style={{ height: "140px" }}
            className="Theme1 d-flex flex-column justify-content-center align-items-center"
          >
            <div className=" d-flex flex-column justify-content-center">
              <div style={{ marginLeft: "1.6rem" }}>
                <label htmlFor="profilePicInput">
                  <img
                    src={
                      profilePicBase64
                        ? `data:image/jpeg;base64,${profilePicBase64}`
                        : defaultProfilePic
                    }
                    alt="Profile"
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      minWidth: "120px",
                      minHeight: "120px",
                      borderRadius: "50%",
                      border: "1px solid goldenrod",
                      boxShadow: "goldenrod 0px 2px 5px",
                    }}
                    className="avatar-img"
                  />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="profilePicInput"
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
                <CiEdit
                  onClick={toggleEdit}
                  style={{
                    position: "absolute",
                    marginTop: -14,
                    color: "white",
                    fontSize: 25,
                    marginLeft: 54,
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ height: "260%" }} className="Theme3">
            <div className="pt-4 d-flex flex-column justify-content-center align-items-center">
              <div className="gap-4 d-flex flex-column justify-content-center">
                {/* Name Field */}
                <div className="gap-3 d-flex">
                  <div className={"text-start"}>
                    <label className={"form-label"}>
                      <p
                        className="ml-1 fw-semibold"
                        style={{ fontSize: "1.05rem" }}
                      >
                        Name
                      </p>
                    </label>
                    <div
                      className={"input-group bg-soft-light rounded-2"}
                      style={{ marginTop: -8, width: "40vw" }}
                    >
                      <span
                        className={"input-group-text"}
                        style={{ background: "aliceblue" }}
                      >
                        <BsPerson />
                      </span>
                      {editableFields ? (
                        <input
                          ref={nameInputRef} // Attach the ref to the input field
                          className={
                            loginStyle["form-control-1"] +
                            " form-control-md form-control"
                          }
                          value={adminData.name}
                          onChange={(e) => handleChange(e, "name")}
                          type="text"
                          required
                        />
                      ) : (
                        <input
                          className={
                            loginStyle["form-control-1"] +
                            " form-control-md form-control"
                          }
                          value={adminData.name}
                          readOnly
                          type="text"
                          required
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* Email Field */}
                <div className="gap-3 d-flex">
                  <div className={"text-start"}>
                    <label className={"form-label"}>
                      <p
                        className="ml-1 fw-semibold"
                        style={{ fontSize: "1.05rem" }}
                      >
                        Email
                      </p>
                    </label>
                    <div
                      className={"input-group bg-soft-light rounded-2"}
                      style={{ marginTop: -8, width: "40vw" }}
                    >
                      <span
                        className={"input-group-text"}
                        style={{ background: "aliceblue" }}
                      >
                        <MdOutlineAttachEmail />
                      </span>
                      <input
                        className={
                          loginStyle["form-control-1"] +
                          " form-control-md form-control"
                        }
                        value={adminData.email}
                        readOnly
                        type="text"
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* Password */}
                <div className={"text-start"}>
                  <label className={"form-label"}>
                    <p
                      className="ml-1 fw-semibold"
                      style={{ fontSize: "1.05rem" }}
                    >
                      Password
                    </p>
                  </label>
                  <div
                    className={"input-group bg-soft-light rounded-2"}
                    style={{ marginTop: -8, width: "40vw" }}
                  >
                    <span
                      className={"input-group-text"}
                      style={{ background: "aliceblue" }}
                    >
                      <RiLockPasswordFill />
                    </span>
                    {editableFields ? (
                      <input
                        className={
                          loginStyle["form-control-1"] +
                          " form-control-md form-control"
                        }
                        value={adminData.password}
                        onChange={(e) => handleChange(e, "password")}
                        type="password"
                        required
                      />
                    ) : (
                      <input
                        className={
                          loginStyle["form-control-1"] +
                          " form-control-md form-control"
                        }
                        value={adminData.password}
                        readOnly
                        type="password"
                        required
                      />
                    )}
                  </div>
                </div>

                {/* Phone Field */}
                <div className="gap-3 d-flex">
                  <div className={"text-start"}>
                    <label className={"form-label"}>
                      <p
                        className="ml-1 fw-semibold"
                        style={{ fontSize: "1.05rem" }}
                      >
                        Phone
                      </p>
                    </label>
                    <div
                      className={"input-group bg-soft-light rounded-2"}
                      style={{ marginTop: -8, width: "40vw" }}
                    >

                      {editableFields ? (
                        <PhoneInput
                          inputProps={{
                            required: true,
                          }}
                          specialLabel={""}
                          country={"us"}
                          value={adminData.phone}
                          onChange={handlePhoneChange}
                          inputStyle={{
                            width: "100%",
                            backgroundColor: "aliceblue",
                            border: "1px solid lightgrey",
                            borderRadius: "0 0.375rem 0.375rem 0",
                          }}
                        />
                      ) : (
                        <>
                        <span
                        className={"input-group-text"}
                        style={{ background: "aliceblue" }}
                      >
                        <FaPhoneAlt />
                      </span>
                        <input
                          className={
                            loginStyle["form-control-1"] +
                            " form-control-md form-control"
                          }
                          value={adminData.phone}
                          readOnly
                          type="text"
                          required
                        />
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {/* Save Button */}
                <div className="d-flex justify-content-center">
                  {editableFields && (
                   <>
                   <button
                    onClick={handleSave}
                    style={{
                      width: "50%",
                      position: "relative",
                      color: "white",
                      background: "goldenrod",
                      marginTop: 1,
                      height: 35,
                      border: "none",
                      borderRadius: 14,
                      marginRight: 10,
                      fontWeight: 500
                    }}
                  >
                    Update
                  </button>
                  <button
                    onClick={handleDelete}
                    style={{
                      width: "50%",
                      position: "relative",
                      color: "white",
                      background: "red",
                      marginTop: 1,
                      height: 35,
                      border: "none",
                      borderRadius: 14,
                      marginLeft: 20,
                      fontWeight: 500
                    }}
                  >
                    Delete
                  </button>
                   </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default User_profile;
