import React, { useEffect, useState } from "react";
import style from "../Style/CSS.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ChatStyle from "../pages/chat/Chat.module.css";
import { useSelector, useDispatch } from "react-redux";
import { ApiEndPoint, decodeToken } from "../pages/components/utils/utlis";
import axios from "axios";
import { Broadcastdata } from "../Redux Toolkit/slice";
const Broadcast = ({searchQuery}) => {
  var dispatch = useDispatch();
  // const users = useSelector((state) => state.Data.usersdetail);
  const [name, setBroadcastData] = useState("");
  const [clients, setSelectedUsers] = useState([]);
  const [boradcast, setBoradcast] = useState([]);
  const jwtToken = sessionStorage.getItem("jwtToken");
  const { decodedToken } = decodeToken(jwtToken);
  const { userId } = decodedToken;
  console.log("userID:", userId);

  const [users, setUsers] = useState([]);
  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getAllUsers`);
      setUsers(response.data);
      const updatedBroadcastDataResponse = await axios.get(
        `${ApiEndPoint}/broadcasts`
      );
      setBoradcast(updatedBroadcastDataResponse.data.broadcasts)
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const handleChange = (e) => {
    setBroadcastData(e.target.value);
  };

  const handleCheckbox = (user, isChecked) => {
    const updatedSelectedUsers = isChecked
      ? [...clients, { id: user.id, phone: user.phone }]
      : clients.filter((selectedUser) => selectedUser.id !== user.id);
    setSelectedUsers(updatedSelectedUsers);
  };

  const handleSubmit = async () => {
    try {
      if (!name || !userId || clients.length === 0) {
        alert("Please fill in all required fields");
        return;
      }
      if (Array.isArray(boradcast) && boradcast.length > 0) {
        for (let i = 0; i < boradcast.length; i++) {
          if (boradcast[i].name === name) {
            alert("Name already exist");
            return true;
          }
        }
      }
      const response = await axios.post(`${ApiEndPoint}/broadcasts`, {
        name,
        userId,
        clients,
      });

      if (response.data) {
        // Make an API call to fetch the updated broadcast data
        const updatedBroadcastDataResponse = await axios.get(
          `${ApiEndPoint}/broadcasts`
        );

        // Dispatch action to update Redux store with the new broadcast data
        dispatch(Broadcastdata(updatedBroadcastDataResponse.data.broadcasts));
      }
      alert("Broadcast created successfully");

      // Uncheck all users and empty the selected users array

      // Additional logic if needed
    } catch (error) {
      console.error("Error creating broadcast:", error);
      alert("Error creating broadcast");
    }
  };
  const filteredUsers = users.filter((user) => {
    if (user.name && typeof user.name === "string") {
      const lowerCaseName = user.name.toLowerCase();
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      return lowerCaseName.includes(lowerCaseSearchQuery);
    }
    return false;
  });
  const sortedUsers = filteredUsers.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  return (
    <div>
      <div className={`Theme3  rounded px-4 `} style={{ marginLeft: "1rem",maxHeight:'91vh' }}>
        <input
          type="text"
          placeholder=" Enter broadcast name "
          onChange={handleChange}
          className="p-2 pl-2 mt-3 messagetemplate inputtemp w-100"
        />
        <div
          style={{
            maxHeight: "calc(100vh - 360px)",
            overflowY: "auto",
            marginTop: "10px",
          }}
        >
          {/* Adjust maxHeight based on your layout and content */}
          {sortedUsers.map((user, index) => (
            <div
              className={
                ChatStyle.userListWidget +
                " col-12 col-lg-5 col-xl-3 list-group-item list-group-item-action border-0"
              }
            >
              <label
                htmlFor={`checkbox-${user.id}`}
                className="gap-2 border-0 list-group-item list-group-item-action d-flex align-items-center"
              >
                {/* Checkbox input */}
                <input
                  id={`checkbox-${user.id}`}
                  type="checkbox"
                  checked={clients.some(
                    (selectedUser) => selectedUser.id === user.id
                  )}
                  onChange={(e) => handleCheckbox(user, e.target.checked)}
                  style={{ height: "20px", width: "20px" }}
                />
                {/* User profile information */}
                <div
                  className="rounded-circle d-flex justify-content-center align-items-center"
                  style={{
                    backgroundImage: user.profilePic
                      ? `url(data:image/jpeg;base64,${user.profilePic})`
                      : "none",
                    backgroundColor: user.profilePic
                      ? "transparent"
                      : user.color_code, // Set background color if profilePic is null
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "40px",
                    height: "40px",
                    margin: "auto",
                    border: `1px solid #FFF`,
                    boxShadow: `none`,
                  }}
                >
                  <div
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      color: "#FFF",
                    }}
                  >
                    {user && user.profilePic
                      ? ""
                      : user.name &&
                        user.name.length > 0 &&
                        user.name.includes(" ")
                      ? user.name[0] + user.name.split(" ")[1][0]
                      : user.name[0][0]}
                  </div>
                </div>
                <div
                  className="flex-grow-1"
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  {user && user.name} {/* Display user's name */}
                </div>
              </label>
            </div>
          ))}
        </div>
        <hr />
        <button
          className={`${style.btn} w-100 mt-3`}
          onClick={handleSubmit}
          style={{ border: "none" }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Broadcast;
