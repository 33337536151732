import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ImCross } from "react-icons/im";
import style from "../Style/CSS.module.css";
import "../Style/CSS.css";
import { GrContactInfo } from "react-icons/gr";
import axios, { Axios } from "axios";
import { SiMicrosoftexcel } from "react-icons/si";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import ContactFormModal, { UpdateForm } from "../pages/ContactForm/ContactForm";
import UserListWidget from "../pages/chat/widgets/UserListWidget";
import ContactFormModal6 from "../pages/ContactForm/ContactForm6";
import ExcelG from '../pages/components/images/simple excel sheet (2).png';
import ContactIc from '../pages/components/images/Add user.png';

import {
  BsPersonAdd,
  BsSortDown,
  BsSortUp,
} from "react-icons/bs";
import { useRef } from "react";

//Remove This Array when Backend data fetch

const Contacts = (calback) => {
  // const Users = useSelector((state) => state.Data.usersdetail);
  const [remainingHeight, setRemainingHeight] = useState(0);
  const userListRef = useRef(null);

  useEffect(() => {
    if (userListRef.current) {
      const totalHeight = window.innerHeight;

      const userListHeight = userListRef.current.offsetHeight;

      setRemainingHeight(userListHeight);
    }
  }, []);
  //state used get data of map array
  const [selectedUser, setSelectedUser] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  const [selectedOption, setSelectedOption] = useState(false);
  const filterRef = useRef(null);
  const handleSortChange = (option) => {
    setShowFilters(false); // Close the dropdown after selecting an option
    // Your sorting logic here based on selected option
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilters(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };
  // var users=useSelector((state)=>state.Data.usersdetail)
  const [users, setUsers] = useState([]);
 

  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, [users]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getAllUsers`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const handleUserClick = (userData) => {
    console.log("User data:", selectedUser);
    setSelectedUser(userData);
  };
 

  const handleCrossClick = () => {
    setSelectedUser(null);
  };
  //Search user method and with place of Array varaible backend variable
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter users based on search query
  const filteredUsers = users
    .filter((user) => {
      // Check if user.name is not null and not undefined
      if (user.name && typeof user.name === "string") {
        const lowerCaseName = user.name.toLowerCase();
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        return lowerCaseName.includes(lowerCaseSearchQuery);
      }
      return false; // Filter out user with null or non-string name
    })
    .sort((a, b) => {
      if (!selectedOption) {
        return a.name.localeCompare(b.name); // Sort A-Z
      }
      if (selectedOption) {
        return b.name.localeCompare(a.name); // Sort Z-A
      }
      return 0; 
    });
  const userListHeight = `calc(98vh - 104px)`;

  return (
    <div
      className=" container-fluid p-0 m-0 border d-flex row"
      style={{ height: `calc(100vh - 51px)`, width: "100%", overflow: "none" ,backgroundColor:'white'}}
    >
      {/* First Column */}
      <div
        className={`px-2 m-0 column col-lg-3 col-md-3 cursor-pointer col-sm-12 border-r-2 border-black-100`}
      >       
        <div className="d-flex justify-content-between align-items-center text-align-center">
          <input
            type="search"
            placeholder="Search User"
            className="p-2 mt-1 mb-1 rounded input"
            style={{
              width: "90%",
            }}
            onChange={handleSearchChange}
            value={searchQuery}
          />
          {/* Filter Icon */}
          <div
            className="filter-container"
            style={{ position: "relative" }}
            ref={filterRef}
          >
            {!selectedOption ? (
              <BsSortDown
                onClick={() => {
                  setSelectedOption(true);
                }}
                style={{
                  width: "30px",
                  height: "30px",
                  color: "gray",
                  cursor: "pointer",
                  paddingLeft: "10px",
                }}
              />
            ) : (
              <BsSortUp
                onClick={() => {
                  setSelectedOption(false);
                }}
                style={{
                  width: "30px",
                  height: "30px",
                  color: "gray",
                  cursor: "pointer",
                  paddingLeft: "10px",
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            paddingTop: "10px",
            fontSize: "1.1rem",
            maxHeight: userListHeight,
            overflowY: "auto",
            margin: "0px",
          }}
        >
          {/* User List Content */}
          {filteredUsers.map((val) => (
            
            <UserListWidget
              key={val.id}
              user={val}
              onClick={() => handleUserClick(val)}
              picture={val.profilepic}
              color={val.color_code}
            />
          ))}
        </div>
      </div>

      {/* Second Column */}
      <div
        className={`Theme3 m-0 p-0 col-md-9 col-lg-9 sm-12 d-flex justify-content-center align-items-center d-none d-md-block d-lg-block `}
      >
        {selectedUser === null ? (
          <div className="gap-3 text-center d-flex flex-column justify-content-center align-items-center h-100 w-100">
            <GrContactInfo className="fs-1" />
            <div>
              <h4>Contact Detail</h4>
              <p>Click any contact to view details</p>
            </div>
          </div>
        ) : (
          <div className="gap-5 pl-5 pr-5 mx-4 text-center d-flex flex-column justify-content-center h-100 ">
           
            <div className="gap-3 text-center d-flex flex-column justify-content-center  h-100">
              <UpdateForm user={selectedUser} />
            </div>
          </div>
        )}
      </div>     
    </div>
  );
};
export default Contacts;
