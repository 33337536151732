import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Modal, ProgressBar, Table, Button } from "react-bootstrap";
import execlIcons from "../components/assets/icons/excelIcons.png";
import { ApiEndPoint } from "../components/utils/utlis";
import { BsCheck2Circle } from "react-icons/bs";

const ExcelUploadPopup = ({ isOpen, onClose }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [responseRows, setResponseRows] = useState([]);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [stripedRows, setStripedRows] = useState(true);

  const handleShowResponseModal = () => setShowResponseModal(true);
  const handleCloseResponseModal = () => setShowResponseModal(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileTypeError(false);
    setUploading(true);
    setUploadProgress(0);

    if (
      file &&
      (file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const config = {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios.post(
          `${ApiEndPoint}/upload`,
          formData,
          config
        );

        if (response.status === 200) {
          if (response.data.length > 0) {
            setResponseRows(response.data);
            handleShowResponseModal();
          } else {
            setUploadProgress(0); // Reset upload progress
          }
        }
        if(response.status===400){
          alert(response.data)
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        window.alert(error);
      }
    } else {
      setFileTypeError(true);
      setUploading(false);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx, .xls",
    maxFiles: 1,
  });

  const handleClose = () => {
    onClose(); // Close the modal passed from parent
    setUploading(false); // Reset uploading state
  };

  return (
    <>
      <Modal
        show={showResponseModal}
        onHide={handleCloseResponseModal}
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Error in Uploading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="justify-content-center text-danger">
            <p>The following rows were not inserted as they already exist:</p>
          </div>
          {responseRows.length > 0 ? (
            <Table bordered={false} hover>
              <thead>
                <tr>
                  <th>Row #</th>
                  <th>Name</th>
                  <th>Phone #</th>
                </tr>
              </thead>
              <tbody>
                {responseRows.map((row, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#F08080" : "#dc3545",
                      }}
                    >
                      {row.rowNum}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#F08080" : "#dc3545",
                      }}
                    >
                      {row.name}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#F08080" : "#dc3545",
                      }}
                    >
                      {row.phoneNumber}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No data to display.</p>
          )}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="secondary border border-none"
            onClick={handleCloseResponseModal}
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "10px 20px",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isOpen} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="ml-50%">Upload Excel File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div {...getRootProps()} className="dropzone-container">
            <input {...getInputProps()} />
            <img
              src={execlIcons}
              alt="Excel"
              style={{
                minHeight: "50px",
                minWidth: "60px",
                color: "goldenrod",
              }}
            />
            <p className="dropzone-text">
              Drag and drop an Excel file here, or click to select one
            </p>
            {fileTypeError && (
              <p className="text-danger">Please upload .xls or .xlsx only.</p>
            )}

            {uploading ? (
              <BsCheck2Circle
                className="text-success"
                style={{
                  color: "green",
                  minHeight: "25px",
                  minWidth: "25px",
                  height: "50px",
                  width: "50px",
                }}
              />
            ) : (
              <div></div>
            )}

<style jsx>{`
    .dropzone-container {
      border: 2px dashed #ccc;
      padding: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .icon-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .excel-icon {
      min-height: 50px;
      min-width: 60px;
      color: goldenrod;
      margin-bottom: 20px;
    }

    .check-icon {
      color: green;
      min-height: 25px;
      min-width: 25px;
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .dropzone-text {
      margin-bottom: 20px;
      font-size: 18px;
    }
  `}</style>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExcelUploadPopup;
