import React, { useState, useEffect, useRef } from "react";
import { Handle, Position } from "reactflow";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import btn1 from "../../pages/components/images/btn3.png";
import btn2 from "../../pages/components/images/btn2.png";

function UrlButtons({ data, id, selected }) {
  const [inputs, setInputs] = useState(data.inputs || [{ text: '', link: '', showText: false, status: false }]);
  const [c, setC] = useState(data.count || 1);
  const textInputRefs = useRef([]);
  const linkInputRefs = useRef([]);

  useEffect(() => {
    setInputs(data.inputs || [{ text: "", link: "" }]);
    setC(data.count || 1);
  }, [data]);

  const handleInputChange = (index, event) => {
    const values = [...inputs];
    if (event.target.name === "text") {
      values[index].text = event.target.value.slice(0, 25); // Truncate text to 25 characters
      if (isURL(event.target.value)) {
        values[index].link = event.target.value;
        values[index].text = ""; // Empty the text input field
        values[index].showText = true; // Show the text input field
      }
    } else {
      if (event.target.value === "") {
        values[index].link = "";
        textInputRefs.current[index]?.focus();
        values[index].showText = false;
      } else {
        values[index].link = event.target.value;
      }
    }
    setInputs(values);
    data.inputs = values; // Update the data object
  };

  const handleAddInput = () => {
    if (c < 3) {
      const newInputs =[...inputs, { text: '', link: '', showText: false, status: false }];
      setInputs(newInputs);
      setC(c + 1);
      data.inputs = newInputs; // Update the data object
      data.count = c + 1; // Update the count in data object
    }
  };

  const handleRemoveInput = () => {
    if (c > 1) {
      const newInputs = inputs.slice(0, -1);
      setInputs(newInputs);
      setC(c - 1);
      data.inputs = newInputs; // Update the data object
      data.count = c - 1; // Update the count in data object
    }
  };
  const handleFocus = (index) => {
    const newInputs = inputs.map((input, i) => ({
      ...input,
      status: i === index,
    }));
    setInputs(newInputs);
  };
  const isURL = (str) => {
    const urlRegex = /\b((http|https|ftp):\/\/)?(([\w-]+\.)+[\w-]{2,})(:\d{2,5})?(\/[^\s]*)?\b/;
    return urlRegex.test(str);
  };

  return (
    <div
    style={{ borderColor: selected ? "#ffca28" : "#e0e0e0", boxShadow: selected ? "0 4px 8px rgba(0,0,0,0.2)" : "0 2px 4px rgba(0,0,0,0.1)" }}
    className={`template w-48 shadow-lg rounded-lg bg-white ${selected ? "border-solid border-1" : ""}`}
  >
      <div className="flex flex-col">
      <div className="px-3 py-1 text-left text-white text-sm font-bold rounded-t-lg bg-gradient-to-r from-[#DAA520] to-[#008080]">
  🔘 Buttons
</div>


<div className="px-2 py-3">
{inputs.map((input, index) => (
      <div
        className="relative flex flex-col mb-2 p-2"
        key={index}
        style={{
          borderBottomWidth: 1,
          width: "100%",
          borderColor: "#ffca28",
          backgroundColor: "#f9f9f9",
          borderRadius: 10,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s, color 0.3s',
        }}
        onClick={() => handleFocus(index)}
      >
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <img
            src={input.showText ? btn2 : btn1}
            height={10}
            width={12}
            style={{ marginRight: 5, color: "#0A89ED" }}
            alt="Button Icon"
          />
  
          <input
            type="text"
            placeholder="Text or URL"
            style={{
              width: "85%",
              height: 25,
              border: "none",
              outline: "none",
              fontSize: 8,
              fontWeight: "bold",
              color: "#0A89ED",
              backgroundColor: '#f9f9f9',
              position: 'relative',
              paddingRight: '30px' // Add padding to accommodate the character count span
            }}
            name="text"
            value={input.text}
            onChange={(event) => handleInputChange(index, event)}
            ref={(el) => (textInputRefs.current[index] = el)}
            onBlur={(e) => {
              e.target.style.backgroundColor = '#f9f9f9';
              e.target.style.color = '#0A89ED';
            }}
            onFocus={(e) => {
              handleFocus(index)
              e.target.style.backgroundColor = '#b3ddb3';
              e.target.style.color = '#000000';
            }}
          />
          <span style={{
            position: 'absolute',
            right: 5,
            fontSize: '8px',
            fontWeight: 'bold',
            color: '#0A89ED'
          }}>
            {input.text.length}/25
          </span>
        </div>
        {input.showText && input.status && (
          <input
            type="text"
            placeholder="Link"
            style={{
              width: "100%",
              height: 25,
              border: "none",
              outline: "none",
              fontSize: 8,
              fontWeight: "bold",
              color: "#0A89ED",
              paddingLeft: 15,
              backgroundColor: '#f9f9f9',
            }}
            name="link"
            value={input.link}
            onChange={(event) => handleInputChange(index, event)}
            onBlur={(e) => {
              e.target.style.backgroundColor = '#f9f9f9';
              e.target.style.color = '#0A89ED';
            }}
            onFocus={(e) => {
              handleFocus(index)
              e.target.style.backgroundColor = '#b3ddb3';
              e.target.style.color = '#000000';
            }}
          />
        )}
        {!input.showText && (
          <Handle
            id={`b-${index}`}
            type="source"
            position={Position.Right}
            style={{
              position: "absolute",
              right: -12,
              top: "38%",
              transform: "translateY(-50%)",
            }}
            className="w-1 rounded-full bg-gray-500"
          />
        )}
      </div>
    ))}
      <div className="rounded-lg flex justify-center mt-2 space-x-2">
        <button
          style={{ color: '#3b82f6' }}
          className="rounded-full flex items-center justify-center"
          onClick={handleAddInput}
        >
          <FaCirclePlus />
        </button>
        <button
          style={{ color: '#3b82f6' }}
          className="rounded-full flex items-center justify-center"
          onClick={handleRemoveInput}
        >
          <FaCircleMinus />
        </button>
      </div>
    </div>
      </div>

      <Handle
        id="a"
        type="target"
        position={Position.Left}
        className="w-1 rounded-full bg-slate-500"
      />
    </div>
  );
}

export default UrlButtons;
