import React, { useState, useEffect, useRef } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import btn1 from "../../pages/components/images/btn3.png";
import btn2 from "../../pages/components/images/btn2.png";

function MessageNode({ data, id, selected }) {
  const [input, setInput] = useState(data.label || "");
  const { setNodes } = useReactFlow();
  const textInputRefs = useRef([]);
  const textareaRef = useRef(null);
  const [showButtons, setShowButtons] = useState(data.showButtons || false);
  const [inputs, setInputs] = useState(data.inputs || [{ text: "", link: "", showText: false, status: false  }]);
  const [c, setC] = useState(data.count || 1);

  useEffect(() => {
    setInputs(data.inputs || [{ text: "", link: "" }]);
    setC(data.count || 1);
    setShowButtons(data.showButtons || false);
  }, [data]);

  const handleInputChange = (index, event) => {
    const values = [...inputs];
    if (event.target.name === "text") {
      values[index].text = event.target.value.slice(0, 25); // Truncate text to 25 characters
      if (isURL(event.target.value)) {
        values[index].link = event.target.value;
        values[index].text = ""; // Empty the text input field
        values[index].showText = true; // Show the text input field
      }
    } else {
      if (event.target.value === "") {
        values[index].link = "";
        textInputRefs.current[index]?.focus();
        values[index].showText = false;
      } else {
        values[index].link = event.target.value;
      }
    }
    setInputs(values);
    data.inputs = values; // Update the data object
  };

  const handleAddInput = () => {
    if (c < 3) {
      const newInputs =[...inputs, { text: '', link: '', showText: false, status: false }];
      setInputs(newInputs);
      setC(c + 1);
      data.inputs = newInputs; // Update the data object
      data.count = c + 1; // Update the count in data object
    }
  };

  const handleRemoveInput = () => {
    if (c > 1) {
      const newInputs = inputs.slice(0, -1);
      setInputs(newInputs);
      setC(c - 1);
      data.inputs = newInputs; // Update the data object
      data.count = c - 1; // Update the count in data object
    }
  };

  const isURL = (str) => {
    const urlRegex = /\b((http|https|ftp):\/\/)?(([\w-]+\.)+[\w-]{2,})(:\d{2,5})?(\/[^\s]*)?\b/;
    return urlRegex.test(str);
  };

  useEffect(() => {
    setInput(data.label || "");
    autoResizeTextarea();
  }, [data.label]);

  useEffect(() => {
    autoResizeTextarea();
  }, [input]);
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 2px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
  const handleChange = (event) => {
    let { value } = event.target;
    autoResizeTextarea(event);

    if (value.length > 550) {
      value = value.slice(0, 550);
    }

    const words = value.split(" ");
    let formattedValue = "";
    let currentLine = "";

    words.forEach((word) => {
      if (currentLine.length + word.length + 1 > 20) {
        formattedValue += currentLine + "\n";
        currentLine = word;
      } else {
        if (currentLine.length > 0) {
          currentLine += " ";
        }
        currentLine += word;
      }
    });

    if (currentLine.length > 0) {
      formattedValue += currentLine;
    }

    setInput(value);

    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          node.data = { ...node.data, label: value };
        }
        return node;
      })
    );
  };
  const handleFocus = (index) => {
    const newInputs = inputs.map((input, i) => ({
      ...input,
      status: i === index,
    }));
    setInputs(newInputs);
  };
  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`;
    }
  };

  const handleToggle = () => {
    setShowButtons(!showButtons);
    data.showButtons = !showButtons; // Ensure data is updated correctly
  };

  return (
    <div
      style={{ borderColor: selected ? "#ffca28" : "#e0e0e0", boxShadow: selected ? "0 4px 8px rgba(0,0,0,0.2)" : "0 2px 4px rgba(0,0,0,0.1)" }}
      className={`template w-48 shadow-lg rounded-lg bg-white ${selected ? "border-solid border-1" : ""}`}
    >
      <div className="flex flex-col">
        <div
          style={{
            background: "linear-gradient(to right, #4A90E2, #50E3C2)"
          }}
          className="max-h-max px-2 py-1 text-left text-white text-xs font-bold rounded-t-md"
        >
          ✉️ Template <span className="ml-12 font-normal text-white">{input.length}/550</span>
        </div>

        <div className="px-2 pt-2 relative">
          <div
            style={{ width: 175}}
            className="text-xs font-normal text-black"
          >
            <textarea
              ref={textareaRef}
              name="inp"
              value={input}
              onChange={handleChange}
              style={{
                minHeight: "30px",
                maxHeight: "100px",
                width: "100%",
                border: "none",
                outline: "none",
                resize: "none",
                overflowY: "auto",
                fontSize: "12px",
                fontWeight: "500",
                color: "#4b5563",
                scrollbarWidth: "thin", // For Firefox
                WebkitScrollbar: {
                  width: "4px",
                },
                WebkitScrollbarTrack: {
                  background: "#f1f1f1",
                },
                WebkitScrollbarThumb: {
                  background: "#888",
                  borderRadius: "4px",
                },
                WebkitScrollbarThumbHover: {
                  background: "#555",
                },
              }}
              className="custom-scrollbar" // For WebKit
              placeholder="Enter Message"
            />
          </div>
          {!showButtons && (
            <Handle
              id="bwww"
              type="source"
              position={Position.Right}
              style={{
                position: "absolute",
                right: -4,
                top: "40%",
                transform: "translateY(-50%)",
              }}
              className="w-1 rounded-full bg-gray-500"
            />
          )}
        </div>

        <div
          style={{
            borderTopWidth: 1,
            borderColor: "#d1d5db",
            borderRadius: 10,
          }}
        >
     <div
  style={{
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 10,
    
    backgroundColor: "#f9fafb", // Light gray background
   
  }}
  className="px-2 pt-2"
>
  <div style={{ width: "70%", color: '#000000' }} className="text-xs fw-200">
    Buttons
  </div>
  <div
    style={{ width: "30%", marginTop: 1 }}
    className="pl-5 rounded cursor-pointer text-blue-500"
  >
    <label className="switch" style={{ height: "13px", width: "27px" }}>
      <input
        type="checkbox"
        checked={showButtons}
        onChange={handleToggle}
      />
      <span className="slider2 round"></span>
    </label>
  </div>
</div>



          {showButtons && (
            <div className="px-2 py-2" >
            {inputs.map((input, index) => (
      <div
        className="relative flex flex-col mb-2 p-2"
        key={index}
        style={{
          borderBottomWidth: 1,
          width: "100%",
          borderColor: "#ffca28",
          backgroundColor: "#f9f9f9",
          borderRadius: 10,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s, color 0.3s',
        }}
        onClick={() => handleFocus(index)}
      >
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <img
            src={input.showText ? btn2 : btn1}
            height={10}
            width={12}
            style={{ marginRight: 5, color: "#0A89ED" }}
            alt="Button Icon"
          />
  
          <input
            type="text"
            placeholder="Text or URL"
            style={{
              width: "85%",
              height: 25,
              border: "none",
              outline: "none",
              fontSize: 8,
              fontWeight: "bold",
              color: "#0A89ED",
              backgroundColor: '#f9f9f9',
              position: 'relative',
              paddingRight: '30px' // Add padding to accommodate the character count span
            }}
            name="text"
            value={input.text}
            onChange={(event) => handleInputChange(index, event)}
            ref={(el) => (textInputRefs.current[index] = el)}
            onBlur={(e) => {
              e.target.style.backgroundColor = '#f9f9f9';
              e.target.style.color = '#0A89ED';
            }}
            onFocus={(e) => {
              handleFocus(index)
              e.target.style.backgroundColor = '#b3ddb3';
              e.target.style.color = '#000000';
            }}
          />
          <span style={{
            position: 'absolute',
            right: 5,
            fontSize: '8px',
            fontWeight: 'bold',
            color: '#0A89ED'
          }}>
            {input.text.length}/25
          </span>
        </div>
        {input.showText && input.status && (
          <input
            type="text"
            placeholder="Link"
            style={{
              width: "100%",
              height: 25,
              border: "none",
              outline: "none",
              fontSize: 8,
              fontWeight: "bold",
              color: "#0A89ED",
              paddingLeft: 15,
              backgroundColor: '#f9f9f9',
            }}
            name="link"
            value={input.link}
            onChange={(event) => handleInputChange(index, event)}
            onBlur={(e) => {
              e.target.style.backgroundColor = '#f9f9f9';
              e.target.style.color = '#0A89ED';
            }}
            onFocus={(e) => {
              handleFocus(index)
              e.target.style.backgroundColor = '#b3ddb3';
              e.target.style.color = '#000000';
            }}
          />
        )}
        {!input.showText && (
          <Handle
            id={`b-${index}`}
            type="source"
            position={Position.Right}
            style={{
              position: "absolute",
              right: -12,
              top: "38%",
              transform: "translateY(-50%)",
            }}
            className="w-1 rounded-full bg-gray-500"
          />
        )}
      </div>
    ))}
            <div className="flex justify-center mt-2 space-x-2">
              <button
                style={{ color: '#3b82f6' }}
                className="rounded-full flex items-center justify-center"
                onClick={handleAddInput}
              >
                <FaCirclePlus />
              </button>
              <button
                style={{ color: '#3b82f6' }}
                className="rounded-full flex items-center justify-center"
                onClick={handleRemoveInput}
              >
                <FaCircleMinus />
              </button>
            </div>
          </div>
          )}
        </div>

        <div>
        <div
  style={{
    borderTopWidth: 1,
    borderColor: "#d1d5db",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "8px",
    backgroundColor: "#f9fafb", // Light gray background
    borderRadius: "8px", // Adding slight rounding for a more card-like feel
    position: "relative",
    marginTop: "8px",
    textAlign: "center",
    color: "#6b7280", // Equivalent to text-gray-500
    fontSize: "0.75rem" // Equivalent to text-xs and fs-10
  }}
  className="max-h-max px-2"
>
  wrong response
  <Handle
    style={{
      position: "absolute",
      right: -3,
      top: "50%",
      transform: "translateY(-50%)",
    }}
    id="bs"
    type="source"
    position={Position.Right}
    className="w-1 rounded-full bg-gray-500"
  />
</div>

        </div>
      </div>

      <Handle
        id="a"
        type="target"
        position={Position.Left}
        className="w-1 rounded-full bg-slate-500"
      />
    </div>
  );
}

export default MessageNode;
