import React, { useEffect, useState } from "react";
import styles from "../Style/tableSytle.module.css";
import { FaTrash } from "react-icons/fa";
import { BsX } from "react-icons/bs";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import axios from "axios";
const initialData = [
  {
    name: "sdas",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "806460228207857",
  },
  {
    name: "fdsf",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "471871281930834",
  },
  {
    name: "sds",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "784621843338373",
  },
  {
    name: "mud",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "982038063511243",
  },
  {
    name: "mudasir_",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "735718148755210",
  },
  {
    name: "mudasir_bh",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "811246140928589",
  },
  {
    name: "dione_softwareee",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "2122515444770053",
  },
  {
    name: "dione_software",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "418028447635821",
  },
  {
    name: "real_estate_template",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "1067634977669615",
  },
  {
    name: "new_hello_template",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "1242849567101258",
  },
  {
    name: "new_template",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "1153618255668068",
  },
  {
    name: "test_template",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "1405676686749361",
  },
  {
    name: "bulk_marketing",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "1134894744511050",
  },
  {
    name: "real_estate",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "954662462701367",
  },
  {
    name: "seasonal_sromotion",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "8333129060046860",
  },
  {
    name: "hello_world",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "UTILITY",
    id: "353350330780349",
  },
  {
    name: "bulk_marketing",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "1134894744511050",
  },
  {
    name: "real_estate",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "954662462701367",
  },
  {
    name: "seasonal_sromotion",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "MARKETING",
    id: "8333129060046860",
  },
  {
    name: "hello_world",
    components: [Array],
    language: "en_US",
    status: "APPROVED",
    category: "UTILITY",
    id: "353350330780349",
  },
];

const DataTable = () => {
  const [messageTemplates, setMessageTemplates] = useState([]);

  const handleDelete = async (val) => {
    console.log("Deleted Delete:", val);
    try {
      const remaining = messageTemplates.filter((value) => value.id !== val.id);
      setMessageTemplates(remaining); // Assuming you want to log the response data
      const response = await axios.delete(`${ApiEndPoint}/message-templates/`, {
        data: {
          id: val.id,
          name: val.name,
        },
      });
      console.log("Response:", response.data);
    } catch (error) {
      console.log("ERROR:", error);
    }
  };
  const changeStatus = (status) => {
    if (status === "APPROVED") {
      return <td className="text-success fw-bold">{status}</td>;
    }
    if (status === "REJECTED") {
      return <td className="text-danger fw-bold">{status}</td>;
    }
    if (status === "WARNING") {
      return <td className="text-danger fw-bold">{status}</td>;
    }
  };
  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getTemplate`);
      setMessageTemplates(response.data.data);
      console.log("Template Messages:", response.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };
  return (
    <center>
      <div
        style={{ width: "100%", paddingTop: 277 }}
        className="d-flex justify-content-center align-items-center"
      >
        <div
          className="row justify-content-center align-items-center position-fixed"
          style={{
            maxHeight: "90vh",
            width: "81%",
          }}
        >
          <div className="col-12">
            <div className={`card shadow-2-strong ${styles.card}`}>
              <div
                className="card-body"
                style={{
                  maxHeight: "90vh",
                  width: "100%",
                }}
              >
                <div
                  className="table-responsive pl-2"
                  style={{
                    maxHeight: "85vh",
                    width: "100%",
                    overflowY: "auto",
                  }}
                >
                  <table
                    className={`table table-borderless mb-0 ${styles.table}`}
                  >
                    <thead>
                      <tr>
                        <th scope="col ">
                          <span>Name</span>
                        </th>
                        <th scope="col">Language</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!Array.isArray(messageTemplates) ||
                      messageTemplates.length === 0 ? (
                        <p></p>
                      ) : (
                        messageTemplates.map((item, index) => {
                          if (item.status === "APPROVED") {
                            return (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.language}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm px-3"
                                    onClick={() => handleDelete(item)}
                                  >
                                    <i className="fa fa-times">
                                      {" "}
                                      <BsX className="fs-5" />
                                    </i>
                                  </button>
                                </td>
                              </tr>
                            );
                          }
                          return null; // Handle the else case in map()
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default DataTable;
