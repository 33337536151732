import React, { useEffect, useState } from "react";
import "../index.css";
import "reactflow/dist/style.css";
import { ApiEndPoint } from "../../pages/components/utils/utlis";
import newchatbot from "../../pages/components/images/Newbot.png";
import {MdDelete} from "react-icons/md";
import axios from "axios";

export default function SidebarList({ onSave, onNew }) {
  const [items, setItems] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const fetchBotData = async (botId) => {
    try {
      const response = await fetch(
        `${ApiEndPoint}/getBotById/${botId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching bot data:", error);
      return { error: "Error fetching bot data" };
    }
  };

  const handleClick = async (item) => {
    console.log("Bot", item);
    const fetchedData = await fetchBotData(item);
    console.log(fetchedData);
    onSave(item,fetchedData);
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  const handleDelete = async (item) => {
    try {
      const getTemplateResponse = await axios.get(`${ApiEndPoint}/getTemplate`);
      if (Array.isArray(getTemplateResponse.data.data)) {
        const templatesList = item["Templates"];
        console.log("Data wants to delete ", templatesList);
        console.log("Response ", getTemplateResponse);
  
        getTemplateResponse.data.data.forEach(template => {
          templatesList.forEach(async itemTemplate => {
            if (template.name === itemTemplate.template_name && template.language === itemTemplate.language) {
              console.log("Matched Template ID:", template.id);
              const deleteResponse = await axios.delete(`${ApiEndPoint}/message-templates/`, {
                data: {
                  id: template.id,
                  name: template.name,
                },
              });
              console.log("Response:", deleteResponse.data);
            }
          });
        });
        
        const deleteBotResponse = await axios.delete(
          `${ApiEndPoint}/deleteBotById/${item["name"]}`
        );
        if (!deleteBotResponse.ok) {
          throw new Error("Network response was not ok");
        }
      }
      console.log("Template Messages:", getTemplateResponse.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  }
  const handleCheckboxClick = (name, currentStatus) => {
    setIsDisabled(true);
    handleToggle(name, currentStatus);
  };
  
  const handleToggle = async (name, currentStatus) => {
    const newStatus = !currentStatus;
    // Call a function to update the status
    try {
      const response = await fetch(
        `${ApiEndPoint}/updateBotStatus`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: name, status: newStatus }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setIsDisabled(false)
    } catch (error) {
      console.error("Error updating bot status:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${ApiEndPoint}/getBotData`
        );
        const data = await response.json();
        if (data) {
          setItems(data);
        } else {
          console.error("Fetched data is not an array:", data);
          setItems([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setItems([]);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <aside
      style={{ width: "17%", borderRadius: 2,backgroundColor:'white',height:'91vh'}}
      className="border-r-2 p-2 border-black-100 text-sm w-56 h-screen text-black-100"
    >     

      <button
  style={{
    width: "100%",
    borderColor: 'goldenrod',
    borderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '1rem',
    backgroundColor: 'white',
    color: 'black',
    height:40,
    fontWeight: '600',
    borderRadius: '0.375rem',
    cursor: 'pointer'
  }}
  className="mb-2"
  onClick={onNew}
>
  <img src={newchatbot} height={45} width={45} alt="New Bot" />
  <span style={{fontSize:20,color:'#565656'}}>New Bot</span>
</button>

      {Array.isArray(items["localJsonData"]) && items["localJsonData"].length > 0 ? (
  <div
    style={{
      width: "100%",
      maxHeight: `calc(93vh - 129px)`,
      overflowY: 'auto',
      overflowX: 'hidden',
    }}
  >
    {items["localJsonData"].map((item, index) => (
      <div
        key={index + 90000}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{ width: "70%" }}
          key={index}
          className="mb-2 p-3 rounded cursor-pointer"
          onClick={() => handleClick(item["name"])}
        >
          <span style={{fontSize:18,color:'#565656'}} >{truncateText(item["name"].toUpperCase(), 4)}</span>
        </div>

        <div
      style={{ width: '29%' }}
      key={index + 20000}
      className="mb-2 pl-1 pr-1 pt-3 rounded cursor-pointer text-blue-500"
    >
      <label className="switch">
        <input
          type="checkbox"
          checked={item.status}
          onChange={() => handleCheckboxClick(item["name"], item["status"])}
          disabled={isDisabled}
        />
        <span className="slider round"></span>
      </label>
    </div>
        <div
          style={{ width: "15%" }}
          key={index + 50000}
          className="mb-2 mt-1 pl-1 pr-1 pt-2 rounded cursor-pointer"
          onClick={()=>{handleDelete(item)}}
        >
          <MdDelete style={{color:'red',height:24,width:25}}/>
        </div>

      </div>
    ))}
  </div>
) : (
  <p></p>
)}

    </aside>
  );
}
