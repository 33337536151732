import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  FaPaperclip,
  FaSmile,
  FaMicrophone,
  FaPaperPlane,
  FaStop,
  FaImage,
} from "react-icons/fa";
import Imag from '../../components/images/gallery.png'
import Clip from '../../components/images/attachment.png'
import Send from '../../components/images/send.png'
import Mic from '../../components/images/mic.png'
import vmsg from "vmsg";
import Modal from "react-modal";
import "../../chat/widgets/chatinputs.css";

const ChatInput = ({
  onSendMessage,
  onSendImage,
  onSendDoc,
  onSendVoice
}) => {
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewCaption, setPreviewCaption] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const fileInputRef = useRef(null);
  const docInputRef = useRef(null);
  const recorderRef = useRef(
    new vmsg.Recorder({
      wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm"
    })
  );

  const handleSendMessage = () => {
    if (message.trim()) {
      setShowSuccessMessage(true); // Show success message
      onSendMessage(message); // Call to send message function
      setMessage(""); // Clear message input field
      setTimeout(() => setShowSuccessMessage(false), 3000); // Hide after 3 seconds
    }
  };

  const handleEmojiClick = (event, emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setPopupMessage("Image must be less than 5MB");
        e.target.value = null; // Reset the file input field
        return;
      }
      setPreviewFile(file);
      e.target.value = null; // Reset the file input field
    }
  };

  const handleDocUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 15 * 1024 * 1024) {
        setPopupMessage("Document must be less than 15MB");
        e.target.value = null; // Reset the file input field
        return;
      }
      setPreviewFile(file);
      e.target.value = null; // Reset the file input field
    }
  };

  const startRecording = async () => {
    setIsRecording(true);
    try {
      await recorderRef.current.initAudio();
      await recorderRef.current.initWorker();
      recorderRef.current.startRecording();
    } catch (e) {
      console.error(e);
      setIsRecording(false);
    }
  };

  const stopRecording = async () => {
    setIsRecording(false);
    try {
      const blob = await recorderRef.current.stopRecording();
      if (blob.size > 5 * 1024 * 1024) {
        setPopupMessage("Audio must be less than 5MB");
        setAudioUrl(null);
        return;
      }
      const audioUrl = URL.createObjectURL(blob);
      setAudioBlob(blob);
      setAudioUrl(audioUrl);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSendAudio = () => {
    if (audioBlob) {
      onSendVoice(audioBlob);
      setAudioBlob(null);
      setAudioUrl(null);
    }
  };

  const handleCancelAudio = () => {
    setAudioBlob(null);
    setAudioUrl(null);
  };

  const handleSendPreviewFile = () => {
    if (previewFile) {
      if (previewFile.type.startsWith("image/")) {
        onSendImage(previewFile, previewCaption);
      } else {
        onSendDoc(previewFile, previewCaption);
      }
      setPreviewFile(null);
      setPreviewCaption("");
    }
  };

  const handleClosePopup = () => {
    setPopupMessage("");
    setPreviewFile(null);
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (popupMessage && !event.target.closest(".popup-modal")) {
        handleClosePopup();
      }
      // Hide success message when clicking outside
      if (showSuccessMessage && !event.target.closest(".success-message")) {
        setShowSuccessMessage(false);
      }
    },
    [popupMessage, showSuccessMessage]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="chat-input">
        <div className="input-container">
          <img
          src={Imag}
          height={25}
          width={25}
            className="icon"
            onClick={() => docInputRef.current.click()}
          />
          <input
            type="file"
            ref={docInputRef}
            onChange={handleImageUpload}
            style={{ display: "none" }}
            accept="image/*"
          />
          <img
          src={Clip}
          height={25}
          width={25}
            className="icon"
            onClick={() => fileInputRef.current.click()}
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleDocUpload}
            style={{ display: "none" }}
            accept=".pdf,.doc,.docx,.xls,.xlsx"
          />
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type a message"
            className="text-input"
          />
          {isRecording ? (
            <FaStop className="icon" style={{color:'red'}} onClick={stopRecording} />
          ) : (
           <img
            src={Mic}
            height={25}
            width={25} className="icon" onClick={startRecording} />
          )}
          <img
          src={Send}
          height={25}
          width={25}className="icon" onClick={handleSendMessage} />
        </div>
      
      {audioUrl && (
        <div className="preview-container pr-7">
          <audio controls src={audioUrl} />
          <div className="preview-buttons">
            <button onClick={handleSendAudio} className="btn btn-success">
              Send
            </button>
            <button
              onClick={handleCancelAudio}
              className="btn btn-danger ml-1"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {previewFile && (
        <div className="preview-container pr-7">
          {previewFile.type.startsWith("image/") ? (
            <p className="preview-file">{previewFile.name}</p>
          ) : (
            <p className="preview-file">{previewFile.name}</p>
          )}
          <div className="preview-buttons">
            <button onClick={handleSendPreviewFile} className="btn btn-success">
              Send
            </button>
            <button
              onClick={() => setPreviewFile(null)}
              className="btn btn-danger ml-1"
            >
              Close
            </button>
          </div>
        </div>
      )}
      {popupMessage && (
        <Modal
          isOpen={!!popupMessage}
          onRequestClose={handleClosePopup}
          className="popup-modal"
          overlayClassName="popup-modal-overlay"
          ariaHideApp={false}
        >
          <h2>Error Message:</h2>
          <div className="popup">
            <p>{popupMessage}</p>
          </div>
        </Modal>
      )}
       {showSuccessMessage ? (
        <Modal
          isOpen={true}
          onRequestClose={handleClosePopup}
          className="popup-modal"
          overlayClassName="popup-modal-overlay"
          ariaHideApp={false}
        >
          <h2>Message:</h2>
          <div className="popup">
            <p>{popupMessage}</p>
          </div>
        </Modal>
      ):(<></>)}
    </div>
  );
};

export default ChatInput;
