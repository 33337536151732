import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaDownload } from "react-icons/fa";
import { ApiEndPoint, formatTimestamp, messageTime, splitSenderName } from "../../components/utils/utlis";
import "./dynamicImage.css";
import styles from "./dynamicDocument.module.css"; 
const DynamicImage = ({
  file_id,
  position,
  timestamp,
  fileName,
  senderName,
  avatar,
  color_code,
}) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`${ApiEndPoint}/downloadFile/${file_id}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
      } catch (error) {
        console.error("Error fetching the image:", error);
      }
    };

    fetchImage();

    // Cleanup URL object when component unmounts
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [file_id, imageUrl]);

  const handleDownload = async () => {
    try {
      const response = await fetch(`${ApiEndPoint}/downloadFile/${file_id}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName || `image.${file_id.split(".").pop()}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up the URL object after download
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <div  className={`${styles["chat-message-" + position]} ${styles["chat-message"]} `}>
       <div className={styles[`avatar-${position} pl-3` ]}>
        <div
          className="rounded-circle d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: avatar ? `url(data:image/jpeg;base64,${avatar})` : "https://bootdey.com/img/Content/avatar/avatar1.png",
            backgroundColor: !avatar ? color_code : "none",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "30px",
            height: "40px",
            margin: "auto",
            border: `1px solid #FFF`,
            boxShadow: `none`,
          }}
        />
        <div className="text-center text-wrap sender-name">
        {splitSenderName(senderName)}
          <br/>
          {messageTime(timestamp)}
        </div>
      
      </div>
      <div className="image-container px-1">
        {imageUrl && <img src={imageUrl} alt={fileName} className="image" />}
        <button className="download-btn" onClick={handleDownload}>
          <FaDownload />
        </button>
        <div className="timestamp">{formatTimestamp(timestamp)}</div>
      </div>
    </div>
  );
};

DynamicImage.propTypes = {
  file_id: PropTypes.string.isRequired,
  position: PropTypes.oneOf(["left", "right"]).isRequired,
  timestamp: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  senderName: PropTypes.string,
  avatar: PropTypes.string,
  color_code: PropTypes.string,
};

export default DynamicImage;
