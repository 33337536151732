import React, { useEffect, useRef, useState } from "react";
import "../chat/Chat.module.css";
import UserListWidget from "./widgets/UserListWidget";
import ChatField from "./widgets/ChatField";
import axios from "axios";

//import ExcelUploadPopup from "../ContactForm/dropzonComponent";
import { useAuth } from "../Auth/AuthContext";
import { ApiEndPoint, decodeToken } from "../components/utils/utlis";
import { useNavigate } from "react-router-dom";



export default function Chat() {
  //const Users = useSelector((state) => state.Data.usersdetail);

  const [users, setUsers] = useState([]);
  const { token, userId, login } = useAuth();
  const navigate = useNavigate();
  const [selectUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false); // State for the main modal
  const [showContactModal, setshowContactModal] = useState(false);
  const [showBulkModal, setBulkModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const filterRef = useRef(null);
  useEffect(() => {
    if (token) {
      const { isExpired } = decodeToken(token);
      if (isExpired) {
        navigate("/");
      }
    }
  }, [navigate, token]); // State for search query
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilters(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 5000);
    return () => clearInterval(intervalId);
  }, [users]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getAllUsers`);
      response.data.map((user) => {
        // console.log("PROFilePIC:");
        return user.profilePic;
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const closeContactModal = () => {
    setshowContactModal(false);
  };

  const openContactModal = () => {
    setshowContactModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter users based on search query
  const filteredUsers = users
  .filter((user) => {
    // Check if user.name is not null and not undefined
    if (user.name && typeof user.name === "string") {
      const lowerCaseName = user.name.toLowerCase();
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      return lowerCaseName.includes(lowerCaseSearchQuery);
    }
    return false; // Filter out user with null or non-string name
  });
  
  const userListHeight = `calc(94vh - 70px)`;
  return (
    <div
      className=" container-fluid p-0 m-0  d-flex row"
      style={{ height: "91vh",backgroundColor:'white',width: "100%" }}
    >
      <div
        className={` px-2 m-0 column col-lg-3 col-md-3 border-r-2 border-black-100 cursor-pointer col-sm-12`}
      >
        
        <div className="d-flex justify-content-between align-items-center text-align-center">
          <input
            type="search"
            style={{
              width: "100%",
            }}
            placeholder="Search User"
            className="p-2 mt-1 mb-1 rounded input aliceblue"
            onChange={handleSearchChange}
            value={searchQuery}
          />
      
      
          </div>
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "20px",
            fontSize: "1.1rem",
            maxHeight: userListHeight,
            overflowY: "auto",
            margin: "0px",
          }}
        >
          {/* User List Content */}
          {filteredUsers.map((val) => (
            <UserListWidget
              key={val.id}
              user={val}
              onClick={() => handleUserClick(val)}
              picture={val.profilePic}
              color={val.profilePic}
            />
          ))}
        </div>
      </div>
      <div
        className={`m-0 p-0 col-md-9 col-lg-9 sm-12 d-flex justify-content-center align-items-center d-none d-md-block d-lg-block `}
      >
        <ChatField className="Theme3" user={selectUser} />
      </div>
    </div>
  );
}
