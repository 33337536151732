import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Modal, ProgressBar, Table, Button, Form } from "react-bootstrap";
import execlIcons from "../../src/pages/components/assets/icons/excelIcons.png";
import {
  ApiEndPoint,
  decodeToken,
} from "../../src/pages/components/utils/utlis";
import { BsCheck2Circle } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Broadcastdata } from "../Redux Toolkit/slice";

const BroadCastModal = ({ isOpen, onClose }) => {
  var dispatch = useDispatch();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [responseRows, setResponseRows] = useState([]);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const handleShowResponseModal = () => setShowResponseModal(true);
  const [boradcast, setBoradcast] = useState([]);
  const handleCloseResponseModal = () => setShowResponseModal(false);
  const [broadcastName, setBroadCastName] = useState(""); // State for list name
  const [selectedFile, setSelectedFile] = useState(null);
  const jwtToken = sessionStorage.getItem("jwtToken");
  const decode = decodeToken(jwtToken);
  const { decodedToken } = decode;
  const { userId } = decodedToken;
  console.log("USERID:", userId);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileTypeError(false);
    if (
      file &&
      (file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setSelectedFile(file); // Set selected file in state
    } else {
      setFileTypeError(true);
    }
  }, []);


  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchUsers = async () => {
    try {
      const updatedBroadcastDataResponse = await axios.get(
        `${ApiEndPoint}/broadcasts`
      );
      setBoradcast(updatedBroadcastDataResponse.data.broadcasts)
    } catch (error) {
      console.error("Error fetching broadcasts:", error);
    }
  };

  const handleListNameChange = (e) => {
    setBroadCastName(e.target.value);
  };
  const uploadFile = async () => {
    try {

      if (!broadcastName || !selectedFile) {
        alert("Please fill in all required fields");
        return;
      }
      if (Array.isArray(boradcast) && boradcast.length > 0) {
        for (let i = 0; i < boradcast.length; i++) {
          if (boradcast[i].name === broadcastName) {
            alert("Name already exist");
            return true;
          }
        }
      }

      setUploading(true); // Start uploading
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("broadcastName", broadcastName);
      formData.append("adminID", userId);

      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        `${ApiEndPoint}/uploadFromExcel`,
        formData,
        config
      );

      console.log(response.data);

      if (response.status === 200) {
        if (response.data.length > 0) {
          setResponseRows(response.data);

          handleShowResponseModal();
          const respons = await axios.get(`${ApiEndPoint}/broadcasts`);
          setSelectedFile(null);
          setBroadCastName("");
          dispatch(Broadcastdata(respons.data.broadcasts));
        } else {
          // Show the response modal
          setUploadProgress(0); // Reset upload progress
        }
        // Update response rows with the data from the server
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      window.alert("Error uploading file. Please try again.");
    } finally {
      setUploading(false); // Stop uploading
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx, .xls",
    maxFiles: 1,
  });
  const [stripedRows, setStripedRows] = useState(true); // State to track alternating row colors

  const toggleStripedRows = () => {
    setStripedRows(!stripedRows);
  };
  return (
    <>
      <Modal
        show={showResponseModal}
        onHide={handleCloseResponseModal}
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Error in Uploading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="justify-content-center text-danger">
            <p>The following rows were not inserted as they already exist:</p>
          </div>

          {responseRows.length > 0 ? (
            <Table bordered={false} hover>
              <thead>
                <tr>
                  <th>Row #</th>
                  <th>Name</th>
                  <th>Phone #</th>
                </tr>
              </thead>
              <tbody>
                {responseRows.map((row, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        backgroundColor: stripedRows
                          ? index % 2 === 0
                            ? "#F08080" // Light red background
                            : "#dc3545" // Dark red background
                          : "",
                      }}
                    >
                      {row.rowNum}
                    </td>
                    <td
                      style={{
                        backgroundColor: stripedRows
                          ? index % 2 === 0
                            ? "#F08080" // Light red background
                            : "#dc3545" // Dark red background
                          : "",
                      }}
                    >
                      {row.name}
                    </td>
                    <td
                      style={{
                        backgroundColor: stripedRows
                          ? index % 2 === 0
                            ? "#F08080" // Light red background
                            : "#dc3545" // Dark red background
                          : "",
                      }}
                    >
                      {row.phoneNumber}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No data to display.</p>
          )}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="secondary border border-none"
            onClick={handleCloseResponseModal}
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "10px 20px",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isOpen} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="ml-50%">Create Broadcast</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="listName">
            <Form.Control
              type="text"
              placeholder="Enter Broadcast Name"
              value={broadcastName}
              onChange={handleListNameChange}
            />
          </Form.Group>
          <div {...getRootProps()} className="dropzone-container mt-1">
            <input {...getInputProps()} />
            {
              <img
                src={execlIcons}
                alt="Excel"
                style={{
                  minHeight: "50px",
                  minWidth: "60px",
                  color: "goldenrod",
                }}
              />
            }
            <p className="dropzone-text">
              Drag and drop an Excel file here, or click to select one
            </p>
            {fileTypeError ? (
              <p className="text-danger">Please upload .xls or .xlsx only.</p>
            ) : (
              selectedFile && <p>File Name: {selectedFile.name}</p>
            )}

            {uploading && uploadProgress === 100 && (
              <BsCheck2Circle
                className="text-sucesss"
                style={{
                  color: "green",
                  minHeight: "25px",
                  minWidth: "25px",
                  height: "50px",
                  width: "50px",
                }}
              />
            )}

            <style jsx>{`
              .dropzone-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 2px dashed #ccc;
                padding: 40px; /* Increased padding for bigger size */
                text-align: center;
              }

              .dropzone-text {
                margin-bottom: 20px;
                font-size: 18px; /* Increased font size */
              }
            `}</style>
          </div>
          {/* Upload button */}
          <div className="d-flex justify-content-center align-items-center">
            <Button
              style={{
                marginTop: 6,
                padding: "10px 20px",
                backgroundColor: "goldenrod",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                width: "100%",
                maxWidth: "100%",
              }}
              className="mt-1 justify-content-center"
              variant="primary"
              disabled={!broadcastName} // Disable button if list name is empty
              onClick={uploadFile} // Call the upload function when button is clicked
            >
              Upload
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BroadCastModal;
