import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import { ImUserPlus } from "react-icons/im";
import { RiFileExcel2Fill, RiFileExcel2Line } from "react-icons/ri";

import style from "../Style/CSS.module.css";
import Broadcast from "./Broadcast";
import { Broadcastdata, Rightscreen } from "../Redux Toolkit/slice";
import ChatStyle from "../pages/chat/Chat.module.css";
import { HiOutlineUserGroup } from "react-icons/hi";
import axios from "axios";
import { LuUserPlus } from "react-icons/lu";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import ExcelUploadPopup from "../pages/ContactForm/dropzonComponent";
import ContactFormModal from "../pages/ContactForm/ContactForm";
import BroadCastModal from "../Modal/broadcast_modal";
import execlIcons from "../../src/pages/components/assets/icons/excelIcons.png";
import Excel from '../pages/components/images/simple excel sheet.png';
import ExcelG from '../pages/components/images/simple excel sheet (2).png';
import ExcelB from '../pages/components/images/BROAD CASTING EXCEL SHEET.png';
import ContactIc from '../pages/components/images/Add user.png';
import Group from '../pages/components/images/group.png';


const Sendmessage = () => {
  var dispatch = useDispatch();
  const broadcastdata = useSelector((state) => state.Data.broadcastdata);
  const dt = useSelector((state) => state.Data.getTemplate);
  const [error, setError] = useState("");
  const [sent, setSent] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedBroadcasts, setSelectedBroadcasts] = useState([]);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [ShowBroadcastPopup, setShowBroadcastPopup] = useState();
  const [showModal, setShowModal] = useState(false); // State for the main modal
  const [showContactModal, setshowContactModal] = useState(false);
  const [showBroadCastModal, setBroadCastModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery3, setSearchQuery3] = useState("");
  let id=null;

  const openBroadCastModal = () => {
    setBroadCastModal(true);
  };
  const closeBroadCastModal = () => {
    setBroadCastModal(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const closeContactModal = () => {
    setshowContactModal(false);
  };

  const openContactModal = () => {
    setshowContactModal(true);
  };

  const handleBroadcastIconClick = () => {
    setShowBroadcastPopup(true); // Set state to true to show File popup
  };

  const fetchBroadcasts = useCallback(async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/broadcasts`);
      console.log("Broadcasts:", response.data);
      dispatch(Broadcastdata(response.data.broadcasts));
    } catch (error) {
      console.error("Error fetching broadcasts:", error);
    }
  }, [dispatch]); // Include dispatch in the dependencies array

  // Call fetchBroadcasts inside useEffect with an empty dependency array
  useEffect(() => {
    fetchBroadcasts(); // Call the fetchBroadcasts function when the component mounts
  }, [fetchBroadcasts]);
  useEffect(() => {
    fetchTemplates();
  }, []);
  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getTemplate`);
      setMessageTemplates(response.data.data);
      if (Array.isArray(response.data.data)) {
        const matchedTemplate = response.data.data.find(e => e.name === dt);
        if (matchedTemplate) {
          setSelectedTemplate(matchedTemplate);
        }
      }
      console.log("Template Messages:", response.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };
  const [users, setUsers] = useState([]);
  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getAllUsers`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const handleSendMessage = async () => {
    if (!selectedTemplate) {
      setError(
        "Please select a template and at least one user to send the message."
      );
      setSent("");
      return;
    }

    try {
      if (contactType === "broadcast") {
        console.log("Selected Template:", selectedTemplate);
        const response = await axios.post(`${ApiEndPoint}/send-messages`, {
          templateName: selectedTemplate.name,
          languageCode: selectedTemplate.language,
          broadcasts: selectedBroadcasts,
        });
        if (response.status === 200) {
          setSent("Message sent successfully ");
          setError("");
        }
      }
      if (contactType === "selectContact") {
        const response = await axios.post(`${ApiEndPoint}/send-messages`, {
          templateName: selectedTemplate.name,
          languageCode: selectedTemplate.language,
          listOfNumbers: selectedUsers.map((user) => ({
            phoneNumber: user.phone,
          })),
        });
        if (response.status === 200) {
          setSent("Message sent successfully ");
          setError("");
        }
      }

      // Optionally, you can update UI or show a success message here
    } catch (error) {
      console.error("Error sending message:", error);
      // Optionally, you can update UI or show an error message here
    }
  };

  const handleTemplateChange = (templateId) => {
    const selectedTemplate = messageTemplates.find(
      (template) => template.id === templateId
    );
    console.log(selectedTemplate)
    setSelectedTemplate(selectedTemplate);
  };
  const handleCheckbox = (user, isChecked) => {
    const updatedSelectedUsers = isChecked
      ? [...selectedUsers, { id: user.id, phone: user.phone }]
      : selectedUsers.filter((selectedUser) => selectedUser.id !== user.id);
    setSelectedUsers(updatedSelectedUsers);
  };
  const handleBroadcast = (val, isChecked) => {
    const updatedSelectedBroadcasts = isChecked
      ? [...selectedBroadcasts, val]
      : selectedBroadcasts.filter((selected) => selected.id !== val.id);
    setSelectedBroadcasts(updatedSelectedBroadcasts);
  };

  const isSelected = (val) => {
    return selectedBroadcasts.some((item) => item.id === val.id);
  };

  const [contactType, setcontactType] = useState("");
  const SelectContactList = (e) => {
    setcontactType(e.target.value);
  };
  const handleSubmit = () => {
    setButtonDisabled(true);
    handleSendMessage();
  };
  const filteredUsers = users.filter((user) => {
    if (user.name && typeof user.name === "string") {
      const lowerCaseName = user.name.toLowerCase();
      const lowerCaseSearchQuery = searchQuery2.toLowerCase();
      return lowerCaseName.includes(lowerCaseSearchQuery);
    }
    return false;
  });
  const sortedUsers = filteredUsers.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  const filteredUsers2 = broadcastdata.filter((user) => {
    if (user.name && typeof user.name === "string") {
      const lowerCaseName = user.name.toLowerCase();
      const lowerCaseSearchQuery = searchQuery3.toLowerCase();
      return lowerCaseName.includes(lowerCaseSearchQuery);
    }
    return false;
  });
  const sortedUsers2 = filteredUsers2.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleSearchChange2 = (e) => {
    setSearchQuery2(e.target.value);
  };
  const handleSearchChange3 = (e) => {
    setSearchQuery3(e.target.value);
  };
  return (
    <div  className={`container-fluid col-12  `} style={{width:'100%',backgroundColor:'white'}}>
      {/* Title */}
      <div
      style={{width:'100%'}}
        className={` col-12 w-100 h-100 d-flex flex-column  gap-3  mt-1  rounded p-4`}
      >
      

        <div>
          <div>
            <select
              className="p-2 form-select input w-100"
              name="category"
              value={selectedTemplate ? selectedTemplate.id : ""}
              onChange={(e) => handleTemplateChange(e.target.value)}
            >
              <option value="">Select message template</option>
              {messageTemplates &&
                messageTemplates.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.name} - {template.language}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div>
          <div className="gap-2 p-3 d-flex flex-column input">
            <div className=" d-flex w-100 justify-content-between">
            <div
  className="gap-1 d-flex"
  style={{ cursor: 'pointer' }}
  onClick={() => document.getElementById('broadcast').click()}
>
  <input
    type="radio"
    id="broadcast"
    name="contentType"
    value="broadcast"
    style={{ marginTop: -5 }}
    checked={contactType === "broadcast"}
    onChange={SelectContactList}
  />
  <label className="fw-sm" style={{ cursor: 'pointer' }} htmlFor="broadcast">Broadcast Group(s)</label>
</div>


              <img
                src={Group}
                height={30}
                width={30}
                title="Add Broadcast"
                onClick={() => setShowBroadcastPopup(true)}
                // className="fw-bold fs-4 "
                style={{ marginLeft: 27 }}
              />
              <img
                src={ExcelB}
                height={30}
                width={30}
                className="fw-bold fs-4 "
                title="Upload Excel File Only"
                onClick={openBroadCastModal}
              />
              {contactType === "broadcast" && (<div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "50%",
                  marginLeft: 30,
                  height:26
                }}
              >
                <input
                  className="p-2 rounded w-100 input aliceblue"
                  type="search"
                  placeholder="Search Broadcast"
                  onChange={handleSearchChange3}
                  value={searchQuery3}
                />
              </div>)}
            </div>
            {contactType === "broadcast" && (
              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 405px)",
                }}
              >
                {sortedUsers2.map((val, index) => (
                  <div key={index} className="mt-4 d-flex">
                    <label className="d-flex align-items-center">
                      <input
                        id={`checkbox-${val.id}`}
                        type="checkbox"
                        checked={selectedBroadcasts.some(
                          (broadcast) => broadcast.id === val.id
                        )}
                        onChange={(e) => handleBroadcast(val, e.target.checked)}
                      />
                      <span className="ms-2 fs-5">{val.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}

            <hr />
            <div className=" d-flex justify-content-between w-100">
            <div
  className="gap-1 d-flex"
  style={{ cursor: 'pointer' }}
  onClick={() => document.getElementById('selectContact').click()}
>
  <input
    type="radio"
    id="selectContact"
    name="contentType"
    value="selectContact"
    style={{ marginTop: -5 }}
    checked={contactType === "selectContact"}
    onChange={SelectContactList}
  />
  <label className="fw-sm" style={{ cursor: 'pointer' }} htmlFor="selectContact">Individual Contact(s)</label>
</div>

              <img
                src={ContactIc}
                height={30}
                width={30}
                className="fw-bold fs-4 ml-6"
                onClick={openContactModal}
              />
              <img
                src={Excel}
                height={30}
                width={30}
                className="fw-bold fs-4 "
                title="Upload Excel File Only"
                onClick={handleShowModal}
              />
               {contactType === "selectContact" && (<div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "50%",
                  marginLeft: 30,
                  height:26
                }}
              >
                <input
                  className="p-2 rounded w-100 input aliceblue"
                  type="search"
                  placeholder="Search Contacts"
                  onChange={handleSearchChange2}
                  value={searchQuery2}
                />
              </div>)}
            </div>
            {contactType === "selectContact" && (
              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 405px)",
                }}
              >
                {sortedUsers &&
                  sortedUsers.map((user, index) => (
                    <div
                      className={
                        ChatStyle.userListWidget +
                        " col-12 col-lg-5 col-xl-3 list-group-item list-group-item-action border-0"
                      }
                      style={{ maxHeight: "100%", overflowY: "auto" }}
                    >
                      <label
                        htmlFor={`checkbox-${user.id}`}
                        className="border-0 list-group-item list-group-item-action d-flex align-items-center"
                      >
                        {/* Checkbox input */}
                        <input
                          id={`checkbox-${user.id}`}
                          type="checkbox"
                          checked={selectedUsers.some(
                            (selectedUser) => selectedUser.id === user.id
                          )}
                          onChange={(e) =>
                            handleCheckbox(user, e.target.checked)
                          }
                        />
                        {/* User profile information */}
                        <div
                          className="rounded-circle d-flex justify-content-center align-items-center"
                          style={{
                            backgroundImage: user.profilePic
                              ? `url(data:image/jpeg;base64,${user.profilePic})`
                              : "none",
                            backgroundColor: user.profilePic
                              ? "transparent"
                              : user.color_code, // Set background color if profilePic is null
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            width: "40px",
                            height: "40px",
                            margin: "auto",
                            border: `1px solid #FFF`,
                            boxShadow: `none`,
                            marginLeft: "10px",
                          }}
                        >
                          <div
                            style={{
                              margin: "auto",
                              textAlign: "center",
                              color: "#FFF",
                            }}
                          >
                            {user && user.profilePic
                              ? ""
                              : user.name &&
                                user.name.length > 0 &&
                                user.name.includes(" ")
                              ? user.name[0] +
                                `${
                                  user.name.split(" ").length > 1 &&
                                  user.name.split(" ")[1]
                                    ? user.name.split(" ")[1][0]
                                    : ""
                                }`
                              : user.name[0][0]}
                          </div>
                        </div>
                        <div
                          className="flex-grow-1"
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          {user && user.name} {/* Display user's name */}
                        </div>
                      </label>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        {/* <button
          className={`${style.btn} w-100 mt-3 mb-3`}
          onClick={handleSubmit}
        >
          Submit
        </button> */}
        <div className="gap-5 p-1 mt-3 d-flex justify-content-around">
        <button
            onClick={handleSubmit}
            disabled={buttonDisabled} // Disable button when buttonDisabled is true
            style={{ backgroundColor: buttonDisabled ? "#d1a236" : "goldenrod", border: "none" }}
            className={`${style.btn}  rounded `}
          >
            Send
          </button>
        </div>
        <div className="p-1 d-flex justify-content-center">
          <p style={{ color: "red", fontSize: 17, fontWeight: "bold" }}>
            {error}
          </p>
          <p style={{ color: "green", fontSize: 17, fontWeight: "bold" }}>
            {sent}
          </p>
        </div>

        <ExcelUploadPopup isOpen={showModal} onClose={handleCloseModal} />
        <ContactFormModal
          isOpen={showContactModal}
          onClose={closeContactModal}
        />
        <BroadCastModal
          isOpen={showBroadCastModal}
          onClose={closeBroadCastModal}
        />

        {/* Render Broadcast popup if showBroadcastPopup is true */}
        {ShowBroadcastPopup && (
          <>
            <Modal
              show={true}
              onHide={() => setShowBroadcastPopup(false)}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Create Broadcast</Modal.Title>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "50%",
                    marginLeft: 10,
                  }}
                >
                  <input
                    className="p-2 rounded w-100 input aliceblue"
                    type="search"
                    placeholder="Search Contacts"
                    onChange={handleSearchChange}
                    value={searchQuery}
                  />
                </div>
              </Modal.Header>
              <Modal.Body>
                <Broadcast searchQuery={searchQuery}/>
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};
export default Sendmessage;
