import React, { useContext, useEffect } from "react";
import ChatStyle from "../Chat.module.css";
import Contactprofile from "../../../pages/components/images/Contact profile logo.png";
import { UserContext } from "./userContext";
export default function UserListWidget({ user, onClick }) {
  const { setSelectedUser } = useContext(UserContext);
  const onSelectUser = (user) => {
    onClick(user);
    setSelectedUser(user);
    localStorage.setItem("user", user.id);
    localStorage.setItem("phone", user.phone);
    localStorage.setItem("name", user.name);
  };
 

  return (
    <div
      className={
        ChatStyle.userListWidget +
        " col-12  col-lg-5 col-xl-3 list-group-item list-group-item-action border-0 "
      }
      style={{ maxHeight: "100%", overflowY: "auto" }}
    >
      {/* Attach onClick event handler to the container div */}
      <div
        onClick={() => onSelectUser(user)}
        className="list-group-item list-group-item-action border-0"
      >
        <div className="d-flex align-items-center ">
          <div
            className="rounded-circle d-flex justify-content-center align-items-center"
            style={{
              backgroundImage: user.profilepic
                ? `url(data:image/jpeg;base64,${user.profilepic})`
                :user.profilePicture?`url(data:image/jpeg;base64,${user.profilePicture})`
                : <img src={Contactprofile} height={40} width={40}/>,
              // backgroundColor: !user.profilepic ? user.color_code : "goldenrod", // Set background color if profilePic is null
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "40px",
              height: "40px",
              margin: "auto",
              boxShadow: `none`,
            }}
          >
            <div style={{ margin: "auto", textAlign: "center", color: "#FFF" }}>
              {user && user.profilepic || user.profilePicture
                ? ""
                : user.name && user.name.length > 0 && user.name.includes(" ")
                ? <img src={Contactprofile} height={45} width={45}/>
                : <img src={Contactprofile} height={45} width={45}/>}
            </div>
          </div>
          <div
            className="flex-grow-1 "
            style={{
              marginLeft: "10px",
            }}
          >
            {user.name &&
              user.name.length > 0 &&
              (user.name.includes(" ")
                ? `${user.name.split(" ")[0]} ${
                    user.name.split(" ").length > 1 && user.name.split(" ")[1]
                      ? user.name.split(" ")[1][0]
                      : ""
                  }`
                : user.name)}
            {/* Display user's name */}
            {/* <div className="small">
              <span className="fas fa-circle chat-online" /> Online
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
