// counterSlice.js
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
  usersdetail: [
    {
      name: "Mudassir Bhatti",
      phoneNumber: "971317368549",
      imageName: "img7.avif",
      email: "bhatti@gmail.com",
    },
    {
      name: "Khatija",
      phoneNumber: "99317368549",
      imageName: "img2.jpg",
      email: "bhatti@gmail.com",
    },
    {
      name: "Hussain",
      phoneNumber: "90317368549",
      imageName: "img3.png",
      email: "bhatti@gmail.com",
    },
    {
      name: "Sabir",
      phoneNumber: "89317368549",
      imageName: "img4.avif",
      email: "bhatti@gmail.com",
    },
    {
      name: "Hamza",
      phoneNumber: "21317368549",
      imageName: "img5.avif",
      email: "bhatti@gmail.com",
    },
    {
      name: "Alyan",
      phoneNumber: "91317368549",
      imageName: "img6.avif",
      email: "bhatti@gmail.com",
    },
    {
      name: "Abubakar",
      phoneNumber: "92317368549",
      imageName: "img8.avif",
      email: "bhatti@gmail.com",
    },
    {
      name: "Zainab",
      phoneNumber: "92317368549",
      imageName: "img2.jpg",
      email: "bhatti@gmail.com",
    },
    {
      name: "Arshad",
      phoneNumber: "92317368549",
      imageName: "img4.avif",
      email: "bhatti@gmail.com",
    },
    {
      name: "Iqra",
      phoneNumber: "99317368549",
      imageName: "img2.jpg",
      email: "bhatti@gmail.com",
    },
    {
      name: "Hussain",
      phoneNumber: "90317368549",
      imageName: "img3.png",
      email: "bhatti@gmail.com",
    },
    {
      name: "Sabir",
      phoneNumber: "89317368549",
      imageName: "img4.avif",
      email: "bhatti@gmail.com",
    },
    {
      name: "Abubakar",
      phoneNumber: "21317368549",
      imageName: "img5.avif",
      email: "bhatti@gmail.com",
    },
    {
      name: "Zainab",
      phoneNumber: "92317368549",
      imageName: "img2.jpg",
      email: "bhatti@gmail.com",
    },
    {
      name: "Arshad",
      phoneNumber: "92317368549",
      imageName: "img4.avif",
      email: "bhatti@gmail.com",
    },
    {
      name: "Iqra",
      phoneNumber: "99317368549",
      imageName: "img2.jpg",
      email: "bhatti@gmail.com",
    },
    {
      name: "Hussain",
      phoneNumber: "90317368549",
      imageName: "img3.png",
      email: "bhatti@gmail.com",
    },
    {
      name: "Sabir",
      phoneNumber: "89317368549",
      imageName: "img4.avif",
      email: "bhatti@gmail.com",
    },
    {
      name: "Abubakar",
      phoneNumber: "21317368549",
      imageName: "img5.avif",
      email: "bhatti@gmail.com",
    },
  ],
  Messagetemplatedata: [],
  count: "",
  broadcastdata: [{ id: 1, name: "New Broad Cast" }],
  users: "",
  isTemplateEditable: false,
  getTemplate:null,
};

const counterSlice = createSlice({
  name: "Reducer",
  initialState,
  reducers: {
    Templatedata: (state, action) => {
      state.Messagetemplatedata = action.payload;
    },
    Rightscreen: (state, action) => {
      state.count = action.payload;
    },
    Broadcastdata: (state, action) => {
      state.broadcastdata = action.payload;
    },
    Users: (state, action) => {
      state.users = action.payload;
    },
    IsEditAble: (state, action) => {
      state.isTemplateEditable = action.payload;
    },
    IsTemplate:(state,action)=>{
      state.getTemplate = action.payload;
    },
  },
});

export const { Users, Templatedata, Rightscreen, Broadcastdata ,IsTemplate} =
  counterSlice.actions;

export default counterSlice.reducer;
