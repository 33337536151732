import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsPerson, BsStackOverflow } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import phone input styles
import axios from "axios";
import { useNavigate } from "react-router-dom";
//import { useAuth } from "../Auth/AuthContext"; // Import your authentication context
import Warning, { ApiEndPoint } from "../components/utils/utlis";
import loginStyle from "../ContactForm/contact.module.css";
import defaultProfilePic from "../components/assets/icons/person.png";
import { MdOutlineAttachEmail } from "react-icons/md";
import { GrContactInfo } from "react-icons/gr";

const ContactForm = ({ onClose }) => {
  // const { login } = useAuth();
  const [showWarning, setShowWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUserName] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const handleCloseWarning = () => setShowWarning(false);
  const [profilePicBase64, setProfilePicBase64] = useState(null);
  const fileInputRef = useRef(null);
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        console.log("Base64:", base64String);
        setProfilePicBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageClick = () => {
    fileInputRef.current.click(); // Trigger click on the hidden file input
  };

  const handleNavigation = async (event) => {
    event.preventDefault();

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      console.log(
        "UserName:",
        username,
        "Email:",
        email,
        "phoneNumber:",
        phoneNumber,
        "image:",
        profilePicBase64
      );

      const requestData = {
        username: username,
        email: email,
        phoneNumber: phoneNumber,
      };

      if (profilePicBase64) {
        requestData.profilePicBase64 = profilePicBase64;
      }

      const response = await axios.post(
        `${ApiEndPoint}/addClient`,
        requestData
      );
      console.log("USER Data:", response.data);
      if (response.status === 200) {
        //alert("Contact Added successfully");
        // Handle success scenario
        onClose();
      }
    } catch (error) {
      console.error("Error:", error.response.status);
      if (error.response.status === 409) {
        setErrorMessage("Contact Already Exists");
        setShowWarning(true); // Show warning modal for user already exists
      } else {
        setErrorMessage("An error occurred. Please try again later.");
        setShowWarning(true); // Show warning modal for other errors
      }
    }
  };

  return (
    <div>
      <form className=" Theme3">
        <div className="mb-2 text-center avatar-container">
          <label htmlFor="profilePicInput">
            <img
              src={
                profilePicBase64
                  ? `data:image/jpeg;base64,${profilePicBase64}`
                  : defaultProfilePic
              }
              alt="Profile"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
                minWidth: "120px",
                minHeight: "120px",
                borderRadius: "50%",
                border: "1px solid goldenrod",
                boxShadow: "goldenrod 0px 2px 5px",
              }}
              className="avatar-img"
              onClick={() => document.getElementById("profilePicInput").click()}
            />
          </label>
          <input
            type="file"
            accept="image/*"
            id="profilePicInput"
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>

        <div className={" text-start d-flex flex-column gap-4"}>
          <div>
            <label className={"form-label font-weight-bold"}>
              <p
                className="ml-3 fw-bold"
                style={{ marginLeft: "3px", fontSize: "1.05rem" }}
              >
                Name
              </p>
            </label>
            <div
              className={"input-group   bg-soft-light rounded-2 "}
              style={{ marginTop: -8}}
            >
              <span className={" input-group-text"}>
                <BsPerson />
              </span>
              <input
                className={
                  loginStyle["form-control-1"] + " form-control-md form-control"
                }
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                id="Name"
                name="username"
                placeholder="Enter Name"
                type="text"
                title="Please Enter Client Name"
                required
              />
            </div>
          </div>

          <div className={" text-start"}>
            <label className={"form-label font-weight-bold"}>
              <p
                className="fw-bold"
                style={{ marginLeft: "3px", fontSize: "1.05rem" }}
              >
                Email
              </p>
            </label>
            <div
              className={"input-group   bg-soft-light rounded-2"}
              style={{ marginTop: -8 }}
            >
              <span className={" input-group-text"}>
                <MdOutlineAttachEmail />
              </span>
              <input
                className={
                  loginStyle["form-control-1"] + " form-control-md form-control"
                }
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="Email"
                name="email"
                placeholder="Enter Email"
                type="email"
                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                title="Enter a valid email address"
                required
              />
            </div>
          </div>
          <div className=" text-start">
            <label className={"form-label"} style={{ marginBottom: "-0.6rem" }}>
              <p
                className=" fw-bold"
                style={{ marginLeft: "3px", fontSize: "1.05rem" }}
              >
                WhatsApp Number
              </p>
            </label>
            <div>
              <PhoneInput
                containerClass="form-control-md mb-1"
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,

                  onFocus: (e) => {
                    // Set multiple styles on focus
                    e.target.style.borderColor = "goldenrod"; // Change border color
                    // Change border color

                    // Add box shadow
                  },

                  onBlur: (e) => {
                    e.target.style.borderColor = "#ccc"; // Revert border color on blur
                    e.target.style.boxShadow = "none"; // Remove box shadow on blur
                  },
                }}
                containerStyle={{
                  borderRadius: "10px",
                }}
                enableSearch={true}
                searchStyle={{
                  width: "100%",
                }}
                disableSearchIcon={true}
                inputStyle={{
                  width: "100%",
                  border: "1px solid",
                  boxShadow: "none",
                  height: "37px",
                }}
                buttonStyle={{}}
                country={"us"}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
              />
            </div>
          </div>

          <div className="d-grid" >
          {showWarning && <p style={{marginTop:-25}} className="text-danger fs-6 mt-0.1rem">{errorMessage}</p>}
          <button
              className={
                loginStyle["btn-color"] +
                " btn btn-warning d-block w-100 text-light fw-bold"
              }
              type="submit"
              onClick={handleNavigation}
            >
              Add Contact
            </button>
          </div>
        
        </div>
        {/* {errorMessage && <p className="text-danger">{errorMessage}</p>} */}
      </form>
      
    </div>
  );
};

export const UpdateForm = ({ user }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [show, setShow] = useState();
  const [username, setUserName] = useState(user.name);
  const [phoneNumber, setPhoneNumber] = useState(user.phone);
  const [email, setEmail] = useState(user.email);
  const [pic, setPic] = useState(user.profilepic);
  const[color_code,setcolor_code]=useState(user.color_code);
  const handleCloseWarning = () => setShowWarning(false);
  const [profilePicBase64, setProfilePicBase64] = useState(null);
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Extract base64 string without data URL prefix
        setProfilePicBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    setUserName(user.name);
    setPhoneNumber(user.phone);
    setEmail(user.email);
    setcolor_code(user.color_code);
    setPic(user.profilepic)
    setShow({
      username: username,
      email: email,
      phoneNumber: phoneNumber,
      profilePicBase64: profilePicBase64,
      pic:pic
    });
  }, [user]);
  const handleSave = async () => {
    await axios
      .put(`${ApiEndPoint}/updateUser/${user.id}`, {
        name: username,
        email: email,
        phone: phoneNumber,
        color_code:color_code,
        profilePic: profilePicBase64,
      })
      .then((response) => {
        console.log(response.data.message); // Log success message from the backend
        if (response.status === 200) {
          setShow(null)
          setProfilePicBase64(null)
        }
      })
      .catch((error) => {
        console.error(error); // Log any errors
      });
  };
  const handleDelete = async () => {
    await axios
      .delete(`${ApiEndPoint}/deleteUser/${user.id}`)
      .then((response) => {
        console.log(response.data.message); // Log success message from the backend
        if (response.status === 200) {
          setShow(null)
          setProfilePicBase64(null)
        }
      })
      .catch((error) => {
        console.error(error); // Log any errors
      });
  };
  // const handleUpdate = async (event) => {
  //   event.preventDefault();

  //   // Email validation regex pattern
  //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!emailPattern.test(email)) {
  //     setErrorMessage("Please enter a valid email address.");
  //     return;
  //   }

  //   try {
  //     console.log(
  //       "UserName:",
  //       username,
  //       "Email:",
  //       email,
  //       "phoneNumber:",
  //       phoneNumber,
  //       "image:",
  //       profilePicBase64
  //     );

  //     const response = await axios.post(`${ApiEndPoint}/update`, {
  //       username: username,
  //       email: email,
  //       phoneNumber: phoneNumber,
  //       profilePicBase64: profilePicBase64,
  //     });
  //     console.log("USER Data:", response.data);
  //     if (response.status === 200) {
  //       console.log("USER success:", response);
  //       // Handle success scenario
  //     }
  //   } catch (error) {
  //     console.error("Error:", error.response.status);
  //     if (error.response.status === 409) {
  //       setErrorMessage("Client already exists.");
  //       setShowWarning(true); // Show warning modal for user already exists
  //     } else {
  //       setErrorMessage("An error occurred. Please try again later.");
  //       setShowWarning(true); // Show warning modal for other errors
  //     }
  //   }
  // };
  return (
    <>
    {show ? (
    <div>
        <div className="mb-2 text-center avatar-container">
          <label htmlFor="profilePicInput">
            <img
              src={
                profilePicBase64                
                  ? `data:image/jpeg;base64,${profilePicBase64}`
                  :pic?`data:image/jpeg;base64,${pic}` : defaultProfilePic
              }
              alt="Profile"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
                minWidth: "120px",
                minHeight: "120px",
                borderRadius: "50%",
                border: "1px solid goldenrod",
                boxShadow: "goldenrod 0px 2px 5px",
              }}
              className="avatar-img"
            />
          </label>
          <input
            type="file"
            accept="image/*"
            id="profilePicInput"
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>

        <div className={" text-start d-flex flex-column gap-4"}>
          <div>
            <label
              className={"form-label font-weight-bold"}
              style={{ marginBottom: "15px" }}
            >
              <p
                className="fw-bold"
                style={{ marginLeft: "3px", fontSize: "1.05rem" }}
              >
                Name
              </p>
            </label>
            <div
              className={"input-group   bg-soft-light rounded-2 "}
              style={{ marginTop: -16 }}
            >
              <span className={" input-group-text"}>
                <BsPerson />
              </span>
              <input
                className={
                  loginStyle["form-control-1"] + " form-control-md form-control"
                }
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                id="Name"
                name="username"
                placeholder="Enter Name"
                type="text"
                title="Please Enter Client Name"
                required
              />
            </div>
          </div>

          <div className={" text-start"}>
            <label
              className={"form-label font-weight-bold"}
              style={{ marginBottom: "15px" }}
            >
              <p
                className=" fw-bold"
                style={{ marginLeft: "3px", fontSize: "1.05rem" }}
              >
                Email
              </p>
            </label>
            <div
              className={"input-group   bg-soft-light rounded-2"}
              style={{ marginTop: -16 }}
            >
              <span className={" input-group-text"}>
                <MdOutlineAttachEmail />
              </span>
              <input
                className={
                  loginStyle["form-control-1"] + " form-control-md form-control"
                }
                value={email ? email : ""}
                onChange={(e) => setEmail(e.target.value)}
                id="Email"
                name="email"
                placeholder="Enter Email"
                type="email"
                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                title="Enter a valid email address"
              />
            </div>
          </div>
          <div className=" text-start">
            <label className={"form-label"}>
              <p
                className="fw-bold "
                style={{
                  marginLeft: "3px",
                  fontSize: "1.05rem",
                  marginBottom: "-2rem",
                }}
              >
                WhatsApp Number
              </p>
            </label>
            <div>
              <PhoneInput
                containerClass="form-control-md"
                inputProps={{
                  name: "phone",
                  onBlur: (e) => {
                    e.target.style.borderColor = "#ccc"; // Revert border color on blur
                    e.target.style.boxShadow = "none"; // Remove box shadow on blur
                  },
                }}
                containerStyle={{
                  borderRadius: "10px",
                }}
                disableSearchIcon={true}
                inputStyle={{
                  width: "100%",
                  border: "1px solid",
                  boxShadow: "none",
                  height: "37px",
                }}
                buttonStyle={{}}
                country={"us"}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <button
              onClick={handleSave}
              style={{
                width: "50%",
                position: "relative",
                color: "white",
                background: "goldenrod",
                marginTop: 9,
                height: 45,
                border: "none",
                borderRadius: 14,
                marginRight: 10,
                fontWeight: 500,
              }}
            >
              Update
            </button>
            <button
              onClick={handleDelete}
              style={{
                width: "50%",
                position: "relative",
                color: "white",
                background: "red",
                marginTop: 9,
                height: 45,
                border: "none",
                borderRadius: 14,
                marginLeft: 20,
                fontWeight: 500,
              }}
            >
              Delete
            </button>
          </div>
        </div>
        
      
    </div>
    ):(<div className="gap-3 text-center d-flex flex-column justify-content-center align-items-center h-100 w-100">
    <GrContactInfo className="fs-1" />
    <div>
      <h4>Contact Detail</h4>
      <p>Click any contact to view details</p>
    </div>
  </div>)}
    </>
  );
};
const ContactFormModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      //centered
      // style={{marginTop:-30,marginBottom:90,height:'100%'}}
      //  size="md"
    >
      {" "}
      <Modal.Header closeButton>
        <Modal.Title>Add Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContactForm onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export default ContactFormModal;
export { ContactForm };
