import React, { useEffect, useState } from "react";
import "../pages/chat/Chat.module.css";
import UserListWidget from "../pages/chat/widgets/UserListWidget";
import axios from "axios";
import User_profile from "./User_profile";
import {BsSortDown,BsSortUp,} from "react-icons/bs";
import { useRef } from "react";
import { ApiEndPoint } from "../pages/components/utils/utlis";
const ViewAdmin = () => {
  //const Users = useSelector((state) => state.Data.usersdetail);
  const [Users, setUsers] = useState([]);
  //const { token, userId, login } = useAuth();
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(false);
  const filterRef = useRef(null);
 
  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, [Users]);
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/admins`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  

 
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter users based on search query
  const filteredUsers = Users
  .filter((user) => {
    // Check if user.name is not null and not undefined
    if (user.name && typeof user.name === "string") {
      const lowerCaseName = user.name.toLowerCase();
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      return lowerCaseName.includes(lowerCaseSearchQuery);
    }
    return false; // Filter out user with null or non-string name
  })
  .sort((a, b) => {
    if (!selectedOption) {
      return a.name.localeCompare(b.name); // Sort A-Z
    }
    if (selectedOption) {
      return b.name.localeCompare(a.name); // Sort Z-A
    }
    return 0; // No sorting if option is not selected
  });

  const userListHeight = `calc(93vh - 130px)`;
  return (
    <div
      className=" container-fluid p-0 m-0 border d-flex row"
      style={{ height:  `calc(93vh - 9px)`, width: "100%)" }}
    >
      <div
        className={` p-2 m-0 column col-lg-3 col-md-3 cursor-pointer border-r-2 border-black-100 bg-white col-sm-12`}
      >
        
        <div className="d-flex justify-content-between align-items-center text-align-center">
     
          <input
            type="search"
            placeholder="Search User"
            className="rounded input aliceblue"
            style={{
              width: "90%",
            }}
            onChange={handleSearchChange}
            value={searchQuery}
          />
          {/* Filter Icon */}
          <div
            className="filter-container"
            style={{ position: "relative" }}
            ref={filterRef}
          >
            {!selectedOption ? (
              <BsSortDown
                onClick={() => {
                  setSelectedOption(true);
                }}
                style={{
                  width: "30px",
                  height: "30px",
                  color: "gray",
                  cursor: "pointer",
                  paddingLeft: "10px",
                }}
              />
            ) : (
              <BsSortUp
                onClick={() => {
                  setSelectedOption(false);
                }}
                style={{
                  width: "30px",
                  height: "30px",
                  color: "gray",
                  cursor: "pointer",
                  paddingLeft: "10px",
                }}
              />
            )}
          </div>
         
        </div>


        <div
          style={{
            fontSize: "1.1rem",
            maxHeight: userListHeight,
            overflowY: "auto",
            marginTop: "2px",
          }}
        >
          {/* User List Content */}
          {filteredUsers.map((val) => (
            <UserListWidget
              key={val.id}
              user={val}
              onClick={() => handleUserClick(val)}
              picture={val.profilePicture}
              color={val.color_code}
            />
          ))}
        </div>
      </div>
      <div
        className={`Theme3 m-0 p-0 col-md-9 col-lg-9 sm-12 d-flex justify-content-center align-items-center d-none d-md-block d-lg-block `}
      >
        {selectedUser && (
          <User_profile className="Theme3" user={selectedUser} />
        )}
      </div>
    </div>
  );
};
export default ViewAdmin;
