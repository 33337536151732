// store.js
import { configureStore } from "@reduxjs/toolkit";
import Reducer from "./slice";

const store = configureStore({
  reducer: {
    Data: Reducer,
  },
});

export default store;
