import React from "react";
import "../Chat.module.css";
import Video from '../../components/images/video call.png';
import Voice from '../../components/images/dialer.png';
import OPT from '../../components/images/options.png';
export default function ChatHeader({ name,phone, profilePic, color_code }) {
  //   const us = user ? user : "Name";
  //   console.log("User Name", us);
 
  return (
    <div
      className="py-0 px-0  d-none d-lg-block mt-1"
      style={{
        maxHeight: "9vh",
        maxWidth: "100vw",
        overflow: "hidden",
      }}
    >
      <div className="d-flex align-items-center ">
        <div
          className="position-relative "
          style={{ marginLeft: "10px", marginRight: "10px" }}
        >
          <div
            className="rounded-circle d-flex justify-content-center align-items-center"
            style={{
              backgroundImage: profilePic
                ? `url(data:image/jpeg;base64,${profilePic})`
                : "none",
              backgroundColor: profilePic ? "transparent" : color_code, // Set background color if profilePic is null
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "50px",
              height: "50px",
              margin: "auto",
              border: `1px solid #FFF`,
              boxShadow: `none`,
            }}
          >
            <div style={{ margin: "auto", textAlign: "center", color: "#FFF" }}>
              {profilePic
                ? ""
                : name && name.length > 0 && name.includes(" ")
                ? name[0] + name.split(" ")[1][0]
                : name[0][0]}
            </div>
          </div>
        </div>
        <div className="flex-grow-1  pl-3">
          <strong className=" fs-5">{name ? name : "Name"}</strong>
          <div className="ml-1 text-muted small">{phone ? `+${phone}` : ""}</div>
        </div>
        <div>
          <button
            className="mr-1"
            style={{
              marginRight: "10px",
             
            }}
          >
            <img
             src={Voice}
              width={25}
              height={25}             
              
            />
              
          </button>
          <button
            className="mr-1"
            style={{
              marginRight: "10px",
            }}
          >
            <img
              src={Video}
              width={25}
              height={25}  
             
            />
          </button>
          <button className="">
            <img
              src={OPT}
              width={25}
              height={25}  
             
            />
              
          </button>
        </div>
      </div>
    </div>
  );
}
